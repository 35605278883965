<template>
  <div class="glance-item rounded cursor-pointer" @click="openSensor()"
    v-if="glanceItem?.ProcessedCameraImageURL && computerVisionValueGreaterThanFiftyPercent(glanceItem?.ComputerVision)">
    <ejs-tooltip :content="glanceItem?.AlertBody" :opensOn="glanceItem?.AlertBody ? 'Auto' : ''">
      <fallback-image :src="glanceItem?.ProcessedCameraImageURL" class="glance-item-image rounded" height="250"
        width="400" />
      <div class="overlay-and-title">
        <template v-if="selectedKey === 'frostVision'">
          <frost-vision-overlay :computer-vision="glanceItem.ComputerVision" size="sm" />
        </template>
        <h3 class="text-sm bg-charcoal transparent-80 text-white">{{ device?.Name }}</h3>
      </div>
    </ejs-tooltip>
  </div>
</template>
<script>
import frostVisionOverlay from './frostVisionOverlay'
import fallbackImage from './fallbackImageComponent'
import { computerVisionValueGreaterThanFiftyPercent } from '../js/utils'

export default {
  components: {
    frostVisionOverlay,
    fallbackImage,
  },
  props: {
    device: {
      type: Object,
      default: null,
    },
    glanceItem: {
      type: Object,
      default: null,
    },
    deviceId: {
      type: String,
    },
    selectedKey: {
      type: String,
    },
  },
  methods: {
    computerVisionValueGreaterThanFiftyPercent,
    openSensor() {
      this.$router.push({ query: { ...this.$route.query, sensorId: this.deviceId } })
    }
  },
};

</script>