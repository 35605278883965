<template src="./fileAttachmentsModal.html"></template>

<script>
import Vue from 'vue'
import { mapStores } from 'pinia';
import { event } from 'vue-gtag';

import { useBaseStore } from '../../js/store';
import dmModalMixin from '../../../dmFramework/dmJS/dmModalMixin'

export default {
    name: 'file-attachments-modal',
    mixins: [dmModalMixin],
    props: [
        "modalData",
    ],
    data: function () {
        return {
            modalName: 'fileAttachmentsModal' + DM.getRandomGuid(),
            lblTitle: 'File Attachments',
            grdFilesFields: [],
            grdFilesItems: [],
            grdFilesSortBy: {
                columns: [
                    { field: 'FileName', direction: 'Ascending' },
                ]
            },
            grdFilesActions: [
                {
                    text: 'Download'
                },
                {
                    text: 'Delete'
                }
            ],
            grdFilesClass: 'dm-bg-color-black',
        }
    },
    computed: {
        ...mapStores(useBaseStore)
    },
    methods: {
        btnAddFilesOnClick() {
            event('add_location_attachment_click', {
                label: 'location_id',
                value: this.modalData.location.ID
            });

            const locationID = this.modalData.location.ID;
            eventBus.$emit('showAppModal', this, 'filesAddModal', {
                mode: 'new',
                locationID: locationID,
                callback: () => {
                    DM.http({
                        method: 'GET',
                        url: `/locations/${locationID}/attachments`,
                    }).then(response => {
                        this.grdFilesItems = response;
                    });
                }
            });
        },
        grdFilesRowOnClick(args) {
            this.selectedRow = args;
        },
        grdFilesActionOnClick(args) {
            if (args.item.text === 'Download') {
                this.downloadFile();
            }

            if (args.item.text === 'Delete') {
                DM.confirmShow('Are you sure you want to delete this file?', '', () => {
                    this.deleteFile(this.selectedRow.rowData.ID);
                }, null, null, 'No');
            }
        },
        downloadFile() {
            event('download_location_attachment_click', {
                'location_id': this.modalData.location.ID,
                'attachment_filename': this.selectedRow.rowData.FileName
            });

            axios({
                method: 'GET',
                url: this.selectedRow.rowData.URL,
                responseType: 'arraybuffer'
            }).then(response => {
                let blob = new Blob([response.data], { type: response.headers['content-type'] });
                let objectUrl = URL.createObjectURL(blob);
                let a = document.createElement("a");
                document.body.appendChild(a);
                a.href = objectUrl;
                a.download = this.selectedRow.rowData.FileName;
                a.click();
                document.body.removeChild(a);
            });
        },
        deleteFile(fileID) {
            const locationID = this.modalData.location.ID;

            event('delete_location_attachment_click', {
                'location_id': locationID,
                'attachment_filename': this.selectedRow.rowData.FileName
            });

            DM.http({
                method: 'DELETE',
                url: `/locations/${locationID}/attachments/${fileID}`,
            }).then(() => {
                this.grdFilesItems = this.grdFilesItems.filter(f => f.ID !== fileID);
            });
        }
    },
    created() {
        let location = this.modalData.location;

        this.mode = this.modalData.mode;

        this.lblTitle = 'File Attachments: ' + location.Name;

        this.grdFilesItems = this.modalData.files;

        let self = this;
        this.grdFilesFields = [
            {
                type: 'template',
                width: '51px',
                template: () => {
                    return {
                        template: Vue.component('columnTemplate', {
                            template: `<ejs-dropdownbutton
                                                :items="grdFilesActions"
                                                :select="select"
                                                iconCss="e-icons e-ellipsis-vert"
                                                cssClass= "e-caret-hide">
                                           </ejs-dropdownbutton>`,
                            data: function () {
                                return {
                                    grdFilesActions: self.grdFilesActions
                                }
                            },
                            methods: {
                                select(args) {
                                    self.grdFilesActionOnClick(args);
                                }
                            }
                        })
                    }
                }
            },
            {
                key: 'FileName',
                label: 'File Name'
            },
        ];
    },
    mounted() {

    }
}
</script>
