<template src="./sensorsPhotoModal.html"></template>

<script>
import { mapStores } from 'pinia';
import { useBaseStore, useGroupsStore } from '../../js/store';

import dmModalMixin from '../../../dmFramework/dmJS/dmModalMixin';
import { formatDate } from '../../js/utils';

export default {
    name: 'sensors-view-modal',
    mixins: [dmModalMixin],
    props: ['modalData'],
    data: function () {
        return {
            selectedReading: null,
            modalName: 'sensorsPhotoModal' + DM.getRandomGuid(),
            sensor: {},
            cellData: {}
        };
    },
    methods: {
        btnCloseModalOnClick() {
            this.modalRef.hide();
        },
        btnDownloadImageOnClick() {
            const { ID, ProcessedCameraImageURL } = this.selectedReading;
            let fileName = `${ID}_${formatDate(ProcessedCameraImageURL)}.jpg`;

            let a = document.createElement('a');
            document.body.appendChild(a);
            a.href = ProcessedCameraImageURL;
            a.download = fileName;
            a.click();
            document.body.removeChild(a);
        },
        overlayClick() {
            this.modalRef.hide();
        },
        formatDateTime(date) {
            const { TimeZone } = this.groupsStore.selectedGroup;
            return formatDate(date, TimeZone)
        }
    },
    computed: {
        ...mapStores(useBaseStore, useGroupsStore),
        backgroundImage() {
            if (this.sensor.ProcessedCameraImageURL) {
                return `url('${this.sensor.ProcessedCameraImageURL}')`;
            }
            return 'none';
        },
        tempUnit() {
            const { TemperatureUnits } = this.groupsStore.selectedGroup;
            return TemperatureUnits?.charAt(0);
        }
    },
    async created() {
        this.sensor = this.modalData.sensor;
        this.cellData = this.modalData.cellData
        this.selectedReading = this.sensor
    }
};
</script> 