var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dm-page-container dm-flex-column dm-padding-md dm-height-100"},[_c('div',{staticClass:"app-settings-page-header dm-justify-content-flex-end dm-padding-bottom-sm dm-align-items-center"},[_c('div',{staticClass:"app-settings-search-container dm-flex-row dm-align-items-center dm-flex-1"},[_c('div',{staticStyle:{"max-width":"300px"}},[_c('ejs-textbox',{attrs:{"floatLabelType":"Auto","placeholder":"Search","showClearButton":true},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}})],1),_c('div',{staticClass:"dm-margin-left-sm"},[_c('ejs-checkbox',{ref:"includeInactive",attrs:{"label":"Include Inactive","checked":_vm.includeInactive},model:{value:(_vm.includeInactive),callback:function ($$v) {_vm.includeInactive=$$v},expression:"includeInactive"}})],1),_c('ejs-button',{staticClass:"dm-margin-left-md dm-flex-none",nativeOn:{"click":function($event){return _vm.searchOnClick.apply(null, arguments)}}},[_c('span',{staticClass:"fa fa-search"}),_vm._v(" "),_c('span',{staticClass:"dm-margin-left-sm app-mobile-hide"},[_vm._v("Search")])])],1),_c('div',{staticClass:"app-settings-navigation-container dm-flex-row dm-align-items-center"},[_c('div',{staticClass:"dm-flex-none e-tab"},[_c('div',{staticClass:"e-tab-header e-control e-toolbar e-lib e-keyboard"},[_c('div',{staticClass:"e-items e-toolbar-items"},[_c('div',{staticClass:"e-item e-toolbar-item dm-flex-column",class:{
                                'app-underline-tab': _vm.$route.name === 'groups', 
                                'e-active': _vm.$route.name === 'groups'
                            },on:{"click":function($event){return _vm.handleNavigation('groups')}}},[_vm._m(0)]),_c('div',{staticClass:"e-item e-toolbar-item app-location-tab",class:{ 
                                'app-underline-tab': _vm.$route.name === 'locations', 
                                'e-active': _vm.$route.name === 'locations'
                            },on:{"click":function($event){return _vm.handleNavigation('locations')}}},[_vm._m(1)]),_c('div',{staticClass:"e-item e-toolbar-item dm-flex-column",class:{
                                'app-underline-tab': _vm.$route.name === 'users', 
                                'e-active': _vm.$route.name === 'users'
                            },on:{"click":function($event){return _vm.handleNavigation('users')}}},[_vm._m(2)])])])]),_c('div',{staticClass:"dm-margin-left-md"},[(_vm.$route.name === 'users')?_c('ejs-button',{attrs:{"is-primary":"true","cssClass":"app-setting-page-add-item-btn blue-gradient"},nativeOn:{"click":function($event){return _vm.handleOpenModalClick('openAddUser')}}},[_c('span',{staticClass:"fa fa-plus-square"}),_vm._v(" "),_c('span',{staticClass:"dm-margin-left-sm app-mobile-hide"},[_vm._v("Add User")])]):_vm._e(),(_vm.$route.name === 'groups' && _vm.isUserSuperAdmin)?_c('ejs-button',{attrs:{"is-primary":"true","cssClass":"app-setting-page-add-item-btn blue-gradient"},nativeOn:{"click":function($event){return _vm.handleOpenModalClick('openAddGroup')}}},[_c('span',{staticClass:"fa fa-plus-square"}),_vm._v(" "),_c('span',{staticClass:"dm-margin-left-sm app-mobile-hide"},[_vm._v("Add Group")])]):_vm._e()],1)])]),_c('div',{staticClass:"dm-flex-column dm-flex-grow dm-overflow-hidden"},[_c('router-view')],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"e-tab-wrap"},[_c('div',{staticClass:"e-text-wrap"},[_c('span',{staticClass:"e-tab-text",attrs:{"role":"presentation"}},[_vm._v("Groups")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"e-tab-wrap"},[_c('div',{staticClass:"e-text-wrap"},[_c('span',{staticClass:"e-tab-text",attrs:{"role":"presentation"}},[_vm._v("Locations")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"e-tab-wrap"},[_c('div',{staticClass:"e-text-wrap"},[_c('span',{staticClass:"e-tab-text",attrs:{"role":"presentation"}},[_vm._v("Users")])])])
}]

export { render, staticRenderFns }