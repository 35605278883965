<template>
  <div v-if="computerVision && highestConfidence.value > 50"
    class="frost-vision-logo-wrapper bg-charcoal transparent-80">
    <template v-if="size === 'sm'">
      <frost-vision-logo-sm :fill="fill" :percentage="percentage" />
    </template>
    <template v-else>
      <frost-vision-logo :fill="fill" :percentage="percentage" />
    </template>
    <span class="text-white">
      {{ label }}
    </span>
  </div>
</template>

<script setup>
import { defineProps } from 'vue'
import { round, reduce } from 'lodash'

import FrostVisionLogo from './frostVisionLogo.vue'
import FrostVisionLogoSm from './frostVisionLogoSm.vue'

import colors from '../js/colors'

const frostVisionMap = {
  Dry: {
    color: colors['dry-surface'],
    label: 'Dry Surface',
  },
  Wet: {
    color: colors['wet-surface'],
    label: 'Wet Surface',
  },
  Snowing: {
    color: colors['snowing'],
    label: 'Snowing',
  },
  Snow: {
    color: colors['snow-surface'],
    label: 'Snowy Surface',
  },
}

const props = defineProps({
  computerVision: Object,
  size: String,
})

// Find highest key/value in computerVision object
let highestConfidence = {}
if (props.computerVision) {
  highestConfidence = reduce(props.computerVision, (acc, value, key) => {
    if (value > acc.value) {
      return { key, value }
    }
    return acc
  }, { key: '', value: 0 }
  )
  // If value is less than 50, don't show anything
  if (highestConfidence.value < 50) {
    highestConfidence = {}
  }
}
const fill = frostVisionMap[highestConfidence.key]?.color
const percentage = round(highestConfidence.value)
const label = frostVisionMap[highestConfidence.key]?.label

</script>