<template>
  <div class="app-sidebar-container dm-flex-none dm-flex-column dm-overflow-hidden dm-height-100"
    :class="{ 'app-mobile-hide': showMap }" style="width: 350px">
    <div v-scroll="handleScroll" class="app-sidebar dm-flex-1 dm-overflow-hidden dm-overflow-y-auto dm-flex-column">
      <div v-for="(group) in locationsStore.filteredLocations" :key="group.GroupID" class="border-sides pt-10">
        <h3 class="group-header" v-if="locationsStore.filteredLocations.length > 1">
          {{ group.GroupName }}
        </h3>
        <sidebar-zone-component :group="group" />
      </div>

      <div v-if="locationsStore.filteredLocations.length === 0"
        class="dm-padding-md dm-flex-row dm-justify-content-center dm-flex-1"
        style="border: 1px solid rgba(0, 0, 0, 0.12); border-bottom: 0;">
        No results were found.
      </div>
    </div>
  </div>
</template>

<script setup>
import Vue from 'vue';

import { useLocationsStore } from '../js/store';
import SidebarZoneComponent from './sidebarZone.vue';

const locationsStore = useLocationsStore();
const props = defineProps({
  showMap: Boolean,
});

function handleScroll() {

}

</script>