<template src="./alertsEditModal.html"></template>

<script>
import { mapStores } from "pinia";
import { event } from "vue-gtag";
import { Sort, CommandColumn, Aggregate, PdfExport, ExcelExport, Group, Toolbar } from "@syncfusion/ej2-vue-grids";
import moment from "moment-timezone";

import { splitCamelCase } from "../../js/utils";
import { useBaseStore } from "../../js/store";
import dmModalMixin from "../../../dmFramework/dmJS/dmModalMixin";

export default {
	name: "alerts-edit-modal",
	mixins: [dmModalMixin],
	props: ["modalData"],
	provide: {
		grid: [Sort, CommandColumn, Aggregate, PdfExport, ExcelExport, Group, Toolbar],
	},
	data: function () {
		return {
			state: {
				grdLocationsItems: [],
			},
			modalName: "alertsEditModal" + DM.getRandomGuid(),
			modalTitle: "Add Alert",
			grdLocationsFields: [
				{
					type: "checkbox",
					width: "50px",
				},
				{
					key: "Name",
					label: "Location",
				},
			],
			grdLocationsItems: [],
			grdLocationsSortBy: {
				columns: [
					{
						field: "Name",
						direction: "Ascending",
					},
				],
			},
			grdPeopleFields: [
				{
					key: "name",
					label: "Person",
				},
				{
					key: 'PhoneNumber',
					label: 'SMS',
					formatter: (column, data) => {
						column.rowData = data;
						if (column.rowData.PhoneNumber) {
							return data.selectedPhone ? '<i class="fa fa-check-square dm-pointer app-grid-checkbox phoneChkBox"></i>' : '<i class="far fa-square dm-pointer app-grid-checkbox phoneChkBox"></i>';
						} else {
							return '<i class="far fa-square dm-pointer app-grid-checkbox phoneDisabled" style="opacity: 0.3;"></i>';
						}
					},
					width: '70px'
				},
				{
					key: "Email",
					label: "EMAIL",
					formatter: (column, data) => {
						column.rowData = data;
						if (column.rowData.Email) {
							return data.selectedEmail
								? '<i class="fa fa-check-square dm-pointer app-grid-checkbox emailChkBox"></i>'
								: '<i class="far fa-square dm-pointer app-grid-checkbox emailChkBox"></i>';
						} else {
							return '<i class="far fa-square dm-pointer app-grid-checkbox emailDisabled" style="opacity: 0.3;"></i>';
						}
					},
					width: "70px",
				},
			],
			grdPeopleItems: [],
			grdPeopleSortBy: {
				columns: [
					{
						field: "LastName",
						direction: "Ascending",
					},
				],
			},
			conditions: [{}],
			readingOptions: [],
			thresholdOptions: [],
			operatorType: null,
			operatorTypeOptions: [],
			stepIndex: 0,
			addASecondCondition: false,
			grdAlertsActions: [
				{
					text: "Edit",
				},
				{
					text: "Delete",
				},
			],
			template: function () {
				return {
					template: Vue.component("bindDropdown", {
						template: `<ejs-dropdownlist 
                                id='dropdownlist' 
                                :dataSource='dropData' 
                                :ref="'dropdownRef'"
                                :open="() => addSearchIcon($refs.dropdownRef)" 
                            />`,
						data() {
							return {
								dropData: ["Order Placed", "Processing", "Delivered"],
							};
						},
						method: {
							addSearchIcon(ref) {
								if (ref) {
									DM.addSearchIconDropDown(ref);
								}
							},
						},
					}),
				};
			},
			searchPeople: "",
			group: null,
			groupOptions: [],
			searchLocations: "",
			selectionOptions: { type: "Multiple" },
			selectedLocations: [],
			onlySendBetween: false,
			onlySendBetweenStartTime: null,
			onlySendBetweenEndTime: null,
			sendAfterNumberOfHoursBetweenAlerts: false,
			selectedSendAfterNumberOfHoursBetweenAlerts: null,
			sendAfterNumberOfHoursBetweenAlertsOptions: [
				{
					text: "1",
					value: 1,
				},
				{
					text: "2",
					value: 2,
				},
				{
					text: "3",
					value: 3,
				},
				{
					text: "4",
					value: 4,
				},
				{
					text: "5",
					value: 5,
				},
				{
					text: "6",
					value: 6,
				},
			],
			sendAfterNumberOfTransmissions: false,
			selectedSendAfterNumberOfTransmissions: null,
			sendAfterNumberOfTransmissionsOptions: [
				{
					text: "1",
					value: 1,
				},
				{
					text: "2",
					value: 2,
				},
				{
					text: "3",
					value: 3,
				},
				{
					text: "4",
					value: 4,
				},
				{
					text: "5",
					value: 5,
				},
			],
			sendAfterConditionsIncludedInFutureForecast: false,
			selectedSendAfterConditionsIncludedInFutureForecast: null,
			sendAfterConditionsIncludedInFutureForecastOptions: [
				{
					text: '1 hour',
					value: 60,
				},
				{
					text: '2 hours',
					value: 120,
				},
				{
					text: '3 hours',
					value: 180,
				},
				{
					text: '4 hours',
					value: 240,
				},
				{
					text: '5 hours',
					value: 300,
				},
				{
					text: '6 hours',
					value: 360,
				},
			],
			readings: [],
			roadConditions: [
				{ road: 'Wet due to snow melting on contact', id: 1 },
				{ road: 'Wet due to melting snow', id: 2 },
				{ road: 'Wet due to rain', id: 3 },
				{ road: 'Patches of wet due to previously fallen rain', id: 4 },
				{ road: 'Icy conditions due to freezing rain', id: 5 },
				{ road: 'Mixed due to snow beginning to freeze', id: 6 },
				{ road: 'Mixed due to slushy melting snow', id: 7 },
				{ road: 'Frozen from snow accumulating', id: 8 },
				{ road: 'Patches of frozen from snow that fell previously', id: 9 },
				{ road: 'Potential for patches black ice (melted snow with refreezing)', id: 10 }
			],
			condition_1: {
				reading: null,
				threshold: null,
				temperature: null,
				MapPinColor: '#008000ff',
				roadConditions: [],
			},
			condition_2: {
				reading: null,
				threshold: null,
				temperature: null,
				roadConditions: [],
			},
			Name: null,
		};
	},
	methods: {
		saveAlert() {
			let isEdit = false;
			let data = DM.copy(this.modalData.alert);

			if (!this.Name || this.Name === '') {
				return DM.alertShow('Invalid alert name, please enter a valid alert name.', 'Required Field Error');
			}

			if (this.modalData.mode === "edit") {
				event("edit_alert_click", {
					distinct_id: this.baseStore.userID,
					user_id: this.baseStore.userID,
					isSuperAdmin: this.baseStore.user.IsSuperAdmin,
					edit_alert_id: this.modalData.alert.ID,
				});

				data.ID = this.modalData.alert.ID;
				isEdit = true;
			} else {
				data.GroupID = this.group;
				event("add_alert_click");
			}

			let startDate = this.onlySendBetweenStartTime;
			let endDate = this.onlySendBetweenEndTime;

			if (typeof startDate !== 'string') {
				let startHours = moment(this.onlySendBetweenStartTime).hours();
				let startMinutes = moment(this.onlySendBetweenStartTime).minutes();
				startDate = moment.utc().hours(startHours).minutes(startMinutes).seconds(0).format('HH:mm:ss');
			}

			if (typeof endDate !== 'string') {
				let endHours = moment(this.onlySendBetweenEndTime).hours();
				let endMinutes = moment(this.onlySendBetweenEndTime).minutes();
				endDate = moment.utc().hours(endHours).minutes(endMinutes).seconds(0).format('HH:mm:ss');
			}

			let recipients = this.grdPeopleItems
				.filter((p) => p.selectedEmail || p.selectedPhone)
				.map((p) => ({
					UserID: p.ID,
					Phone: p.selectedPhone,
					Email: p.selectedEmail,
					UserEmbedded: {
						FirstName: p.FirstName,
						LastName: p.LastName,
					},
				}));

			let roadConditions = this.condition_1.roadConditions;
			if (this.condition_2.reading === "RoadConditions") {
				roadConditions = this.condition_2.roadConditions;
			}

			let temp2 = undefined;
			if (this.condition_2.reading !== "RoadConditions") {
				temp2 = this.addASecondCondition ? this.condition_2.temperature : undefined
			}

			return DM.http({
				method: !isEdit ? "POST" : "PUT",
				url: !isEdit ? "/alerts" : `/alerts/${data.ID}`,
				data: {
					GroupID: data.GroupID,
					Name: this.Name,
					Reading1: this.condition_1.reading,
					Threshold1: this.condition_1.threshold,
					Temperature1: this.condition_1.reading !== "RoadConditions" ? this.condition_1.temperature : -9999,
					MapPinColor: this.condition_1.MapPinColor,
					Condition1And2Operator: this.addASecondCondition ? this.operatorType : "Unknown",
					Reading2: this.addASecondCondition ? this.condition_2.reading : undefined,
					Threshold2: this.addASecondCondition ? this.condition_2.threshold : undefined,
					Temperature2: temp2,
					TimeRangeStart: this.onlySendBetween ? startDate : undefined,
					TimeRangeEnd: this.onlySendBetween ? endDate : undefined,
					NumberOfHoursBetweenAlerts: this.sendAfterNumberOfHoursBetweenAlerts ? this.selectedSendAfterNumberOfHoursBetweenAlerts : undefined,
					NumberOfTransmissions: this.sendAfterNumberOfTransmissions ? this.selectedSendAfterNumberOfTransmissions : undefined,
					ConditionsInForecastMinutes: this.sendAfterConditionsIncludedInFutureForecast ? this.selectedSendAfterConditionsIncludedInFutureForecast : undefined,
					Recipients: recipients,
					Locations: this.selectedLocations.map((l) => ({ LocationID: l.ID })),
					RoadConditions: roadConditions.length > 0 ? roadConditions.join(",") : undefined,
				},
			});
		},
		btnNextOnClick() {
			if (this.stepIndex < 3) {
				this.stepIndex++;
				this.setExpandedStep();
			} else {
				this.saveAlert().then((response) => {
					this.modalData.callback();
					this.modalRef.hide();
				});
			}
		},
		btnBackOnClick() {
			this.stepIndex--;
			this.setExpandedStep();
		},
		setExpandedStep() {
			for (let i = 0; i < 4 - this.stepIndex; i++) {
				this.$refs.accordion.ej2Instances.vueInstance.enableItem(4 - i, false);
			}
			this.$refs.accordion.ej2Instances.vueInstance.enableItem(this.stepIndex, true);
			this.$refs.accordion.ej2Instances.vueInstance.expandItem(true, this.stepIndex);
		},
		btnCancelOnClick() {
			this.modalRef.hide();
		},
		beforeExpand: function (ExpandEventArgs) {
			let obj = this.$refs.accordion.ej2Instances;
			let ele = obj.element.querySelectorAll(".e-selected")[0];
		},
		grdLocationsRowOnClick(data) {
			setTimeout(() => {
				this.selectedLocations = this.$refs.grdLocationsList.getSelectedRecords();
			});
		},
		grdLocationsActionOnClick(data) { },
		grdPeopleRowOnClick(data) {
			if (data.column.field === "PhoneNumber" && data.rowData.PhoneNumber) {
				data.rowData.selectedPhone = !data.rowData.selectedPhone;
			}

			if (data.column.field === "Email" && data.rowData.Email) {
				data.rowData.selectedEmail = !data.rowData.selectedEmail;
			}

			this.$refs.grdPeople.$refs.grdTableList.refresh();
		},
		grdPeopleActionOnClick(data) { },
		formatColumn(field) {
			if (field.type === "date") {
				return { type: "date", format: "MM/dd/yyyy" };
			}

			if (field.type === "time") {
				return { type: "dateTime", format: "hh:mm a" };
			}

			if (field.type === "custom") {
				return field.format;
			}
		},
		getLocations() {
			DM.http({
				method: "GET",
				url: "/locations",
			}).then((response) => {
				this.locations = response;

				this.grdLocationsItems = this.locations;

				if (this.groupOptions.length > 0) {
					this.btnSearchLocationsOnClick();
				}

				if (this.modalData.mode === "edit") {
					setTimeout(() => {
						this.selectLocations();
					}, 100);
				}
			});
		},
		selectLocations() {
			let rows = this.$refs.grdLocationsList.getRows();
			let indexes = [];

			rows.forEach((r) => {
				let selectedRow = this.modalData.alert.Locations.filter((l) => l.ID === r.locationID)[0];
				if (selectedRow) {
					indexes.push(r.rowIndex);
				}
			});

			this.$refs.grdLocationsList.selectRows(indexes);
		},
		rowDataBound(rowData) {
			rowData.row.locationID = rowData.data.ID;
		},
		getGroupOptions() {
			DM.http({
				method: "GET",
				url: "/groups",
			}).then((response) => {
				this.groups = response;
				this.groupOptions = response.map((g) => {
					return {
						text: g.Name,
						value: g.ID,
					};
				});

				if (this.modalData.mode === "edit") {
					this.group = this.modalData.alert.GroupID;
				} else {
					this.group = this.groupOptions[0].value;
				}

				if (this.grdLocationsItems.length > 0) {
					this.btnSearchLocationsOnClick();
				}
			});
		},
		getPeopleOptions() {
			let params = {};
			if (this.searchParams) {
				params = this.searchParams;
			}

			DM.http({
				method: "GET",
				url: "/users",
				params: params,
			}).then((response) => {
				response.forEach((u) => {
					u.name = u.LastName + ", " + u.FirstName;
					if (this.modalData.mode === "edit") {
						let selectedUser = this.modalData.alert.Recipients.filter((r) => r.UserID === u.ID)[0];
						if (selectedUser) {
							u.selectedPhone = selectedUser.Phone;
							u.selectedEmail = selectedUser.Email;
						} else {
							u.selectedPhone = false;
							u.selectedEmail = false;
						}
					} else {
						u.selectedPhone = false;
						u.selectedEmail = false;
					}
				});
				this.people = response;

				this.grdPeopleItems = this.people.filter((p) => {
					return p.Groups.filter((g) => g.GroupID === this.group).length > 0;
				});
			});
		},
		btnSearchLocationsOnClick() {
			this.grdLocationsItems = this.locations;

			if (this.group) {
				this.grdLocationsItems = this.grdLocationsItems.filter((l) => l.GroupEmbedded.ID === this.group);
			}

			if (this.searchLocations) {
				this.grdLocationsItems = this.grdLocationsItems.filter((l) =>
					l.Name.toLowerCase().includes(this.searchLocations.toLowerCase())
				);
			}

			this.$refs.grdLocationsList.ej2Instances.dataSource = this.gridLocationsItems;
		},
		btnSearchPeopleOnClick() {
			this.filterPeople();
		},
		filterPeople() {
			if (this.selectedLocations.length === 0) {
				return;
			}

			if (this.baseStore.user.IsSuperAdmin) {
				this.grdPeopleItems = this.people?.filter((p) => {
					return p.Groups.filter((g) => g.GroupID === this.selectedLocations[0].GroupEmbedded.ID).length > 0 || p.IsSuperAdmin;
				});
			} else {
				this.grdPeopleItems = this.people?.filter((p) => {
					return p.Groups.filter((g) => g.GroupID === this.selectedLocations[0].GroupEmbedded.ID).length > 0;
				});
			}

			if (this.searchPeople) {
				this.grdPeopleItems = this.grdPeopleItems?.filter((p) => {
					return (
						p.LastName.toLowerCase().includes(this.searchPeople.toLowerCase()) ||
						p.FirstName.toLowerCase().includes(this.searchPeople.toLowerCase())
					);
				});
			}
		},
		getThresholdOptions() {
			DM.http({
				method: "GET",
				url: "/alerts/threshold-types",
			}).then((response) => {
				this.thresholdOptions = response.map((t) => ({ text: splitCamelCase(t), value: t }));
			});
		},
		getOperatorTypeOptions() {
			DM.http({
				method: "GET",
				url: "/alerts/operator-types",
			}).then((response) => {
				this.operatorTypeOptions = response.map((t) => ({ text: splitCamelCase(t), value: t }));
			});
		},
		getReadingOptions() {
			DM.http({
				method: "GET",
				url: "/alerts/reading-types",
			}).then((response) => {
				this.readings = response;
				this.readingOptions = response.map((t) => ({
					text: t.Key,
					value: t.Value,
					IsPreset: t.IsPreset,
				}));
			});
		},
		reading1Changed(data) {
			const reading = data.itemData.value;
			this.$refs.thresholdRef1.ej2Instances.value = null;

			this.condition_1.temperature = "";
			this.condition_1.roadConditions = [];
			if (reading === "RoadConditions") {
				this.condition_1.threshold = "Contains";
			} else {
				this.condition_1.threshold = null;
			}

			if (reading === 'FrostAlert') {
				this.addASecondCondition = false;
			}

			if (reading === 'FrostAlert' || reading === this.condition_2.reading) {
				if (this.$refs.readingRef2) this.$refs.readingRef2.ej2Instances.value = null;
				this.$refs.thresholdRef2.ej2Instances.value = null;

				this.operatorType = null;
				this.condition_2.reading = null;
				this.condition_2.threshold = null;
				this.condition_2.temperature = "";
				this.condition_2.roadConditions = [];
			}
		},
		reading2Changed(data) {
			const reading = data.itemData.value;
			this.$refs.thresholdRef2.ej2Instances.value = null;

			this.condition_2.temperature = "";
			this.condition_2.roadConditions = [];
			if (reading === "RoadConditions") {
				this.condition_2.threshold = "Contains";
			} else {
				this.condition_2.threshold = null;
			}
		},
		addSearchIcon(ref) {
			if (ref) {
				DM.addSearchIconDropDown(ref);
			}
		},
		createDateTimeZone(time, timezone) {
			return new Date(moment.utc(time, 'HH:mm:ss').tz(timezone).format('YYYY-MM-DDTHH:mm:ss'));
		},
		beforeRender: function (args) {
			const phoneDisabled = args.target.classList.contains('phoneDisabled')
			const emailDisabled = args.target.classList.contains('emailDisabled')
			const phoneChkBox = args.target.classList.contains('phoneChkBox')
			const emailChkBox = args.target.classList.contains('emailChkBox')

			if (phoneDisabled) {
				this.$refs.tooltip.ej2Instances.content = "This user has still to provide a phone number.";
			} else if (emailDisabled) {
				this.$refs.tooltip.ej2Instances.content = "This user has still to provide an email.";
			} else if (phoneChkBox) {
				this.$refs.tooltip.ej2Instances.content = "User phone number.";
			} else if (emailChkBox) {
				this.$refs.tooltip.ej2Instances.content = "User email address.";
			}
		},
		beforeClose: function (args) {
			this.$refs.tooltip.ej2Instances.content = ""
		},
		handleAddSecondCondition() {
			this.condition_2 = {
				reading: null,
				threshold: null,
				temperature: null,
				roadConditions: [],
			}
		},
		getFilteredThreshold(reading) {
			if (reading === 'RoadConditions') return ["Contains"];
			return ["IsAbove", "IsBelow"];
		}
	},
	computed: {
		...mapStores(useBaseStore),
		validateCurrentStep() {
			if (this.stepIndex === 0) {
				return this.selectedLocations.length > 0;
			} else if (this.stepIndex === 1) {
				if (this.addASecondCondition) {
					return this.condition_1.reading && this.condition_2.reading;
				}

				return this.condition_1.reading;
			} else if (this.stepIndex === 2) {
				let selectedPeople = this.people.filter((p) => p.selectedPhone || p.selectedEmail);
				return selectedPeople.length > 0;
			} else if (this.stepIndex === 3) {
				return true;
			}
		},
		groupTempUnit() {
			if (this.selectedLocations?.length > 0 && this.groups?.length > 0) {
				const groupID = this.selectedLocations[0].GroupEmbedded.ID;
				return this.groups.filter((g) => g.ID === groupID)[0].TemperatureUnits[0];
			}

			return "";
		},
		selectedReadingIsPreset() {
			const selectedReading = this.readings.filter((r) => r.Value === this.condition_1.reading);
			if (selectedReading && selectedReading.length) {
				return selectedReading[0].IsPreset;
			}

			return false;
		},
		reading2Options() {
			return this.readingOptions
				.filter(r => !r.IsPreset)
				.filter(r => !(r.value === "RoadConditions" && this.condition_1.reading === "RoadConditions"));
		},
		checkForecastCondition() {
			return this.condition_1.reading?.includes('Forecast') || this.condition_2.reading?.includes('Forecast') ||
				this.condition_1.reading === 'RoadConditions' || this.condition_2.reading === 'RoadConditions';
		},
		checkNoForecastCondition() {
			return !this.condition_1.reading?.includes('Forecast') || !this.condition_2.reading?.includes('Forecast');
		}
	},
	created() {
		this.getLocations();
		this.getGroupOptions();
		this.getPeopleOptions();
		this.getReadingOptions();
		this.getOperatorTypeOptions();

		let alert = this.modalData.alert;
		// this.mode = this.modalData.mode;

		this.sendAfterNumberOfHoursBetweenAlerts = true;
		this.selectedSendAfterNumberOfHoursBetweenAlerts = 6;
		this.Name = alert.Name;

		if (this.modalData.mode === "edit") {
			this.modalTitle = "Edit Alert";
			Object.assign(this.condition_1, {
				reading: alert.Reading1,
				threshold: alert.Threshold1,
				temperature: alert.Temperature1,
				MapPinColor: alert.MapPinColor,
				roadConditions: alert.Reading1 === 'RoadConditions' ? alert.RoadConditions.split(',').map((e) => parseInt(e)) : []
			});

			this.operatorType = alert.Condition1And2Operator;

			if (alert.Reading2) {
				this.addASecondCondition = true;
				this.condition_2 = {
					reading: alert.Reading2,
					threshold: alert.Threshold2,
					temperature: alert.Temperature2,
					roadConditions: alert.Reading2 === 'RoadConditions' ? alert.RoadConditions.split(',').map((e) => parseInt(e)) : []
				}
			}

			if (alert.TimeRangeStart && alert.TimeRangeEnd) {
				this.onlySendBetween = true;
			}

			this.onlySendBetweenStartTime = alert.TimeRangeStart ? this.createDateTimeZone(alert.TimeRangeStart, alert.GroupEmbedded.TimeZone) : null;
			this.onlySendBetweenEndTime = alert.TimeRangeEnd ? this.createDateTimeZone(alert.TimeRangeEnd, alert.GroupEmbedded.TimeZone) : null;

			if (alert.NumberOfHoursBetweenAlerts) {
				this.sendAfterNumberOfHoursBetweenAlerts = true;
			}
			this.selectedSendAfterNumberOfHoursBetweenAlerts = alert.NumberOfHoursBetweenAlerts;

			if (alert.NumberOfTransmissions) {
				this.sendAfterNumberOfTransmissions = true;
			}
			this.selectedSendAfterNumberOfTransmissions = alert.NumberOfTransmissions;

			if (alert.ConditionsInForecastMinutes) {
				this.sendAfterConditionsIncludedInFutureForecast = true;
			}
			this.selectedSendAfterConditionsIncludedInFutureForecast = alert.ConditionsInForecastMinutes;
		}

		this.$watch("selectedLocations", (newVal) => {
			this.filterPeople();
		});
	},
	mounted() {
		this.setExpandedStep();
	},
};
</script>
