<template>
  <div class="dm-page-container sensor-view" ref="sensorWindow">
    <div class="dm-flex-row justify-space-between dm-padding-left-md dm-padding-right-md">
      <h3 class="text-blue underline-dotted text-lg mb-0 sensor-view-title">{{ sensor?.Name }}</h3>
      <button class="e-btn back-btn btn-sm rounded-sm dm-flex" @click="$emit('handle-go-back')">
        <i class="fa fa-arrow-circle-left"></i>
        <span>Back</span>
      </button>
    </div>
    <div class="dm-flex-row dm-padding-md sensor-header" :class="{ 'sensor-header-stacked': stacked }">
      <div class="dm-flex-column dm-padding-right-md sensor-meta-and-tools">
        <forecast-icons :forecast="forecasts" :is-metric="isMetric" :time-zone="groupsStore.timeZone"></forecast-icons>
        <div v-if="!stacked" class="desktop-sensor-tools">
          <sensor-tools :sensor-id="sensorId" :status="status" @handle-status-update="handleStatusUpdate"
            :user-has-full-access="userHasFullAccess" :wrapper-classes="{ 'dm-flex-column': true }"></sensor-tools>
        </div>
      </div>
      <div class="dm-flex-column image-carousel">
        <div class="relative max-h-500">
          <button class="image-advance prev" aria-label="Previous Image" type="button" @click="previousTransmission()">
            <div class="arrow-wrapper">

              <span class="mdi mdi-arrow-left-circle"></span>
            </div>
          </button>
          <button class="image-advance next" aria-label="Next Image" :class="{ disabled: transmissionIndex === 0 }"
            type="button" @click="nextTransmission()">
            <div class="arrow-wrapper">

              <span class="mdi mdi-arrow-right-circle"></span>
            </div>
          </button>
          <image-with-overlay :data-transmission="selectedReading" :temparature-unit="temparatureUnit"
            :is-metric="isMetric" :key="selectedReading.TransmissionDateTimeUTC" />
        </div>
        <div class="dm-flex-row dm-margin-top-md additional-images">
          <template v-for="(image, $index) in additionalImages">
            <ejs-tooltip :content="image.text" opensOn="Auto" :class="{ active: image.index === transmissionIndex }">
              <fallback-image :src="image.url" :alt="image.text" class="dm-flex"
                :class="{ active: image.url === selectedReading?.ProcessedCameraImageURL }" :click="handleImageClick"
                :data-index="image.index" />
            </ejs-tooltip>
          </template>
        </div>
      </div>
    </div>

    <div v-if="stacked" class="dm-padding-md">
      <sensor-tools :sensor-id="sensorId" :status="status" @handle-status-update="handleStatusUpdate"
        :user-has-full-access="userHasFullAccess"
        :wrapper-classes="{ 'dm-flex-row justify-space-between': true }"></sensor-tools>
    </div>
    <div class="dm-flex-row sensor-wrapper">
      <sensor-chart :sensor="sensor" :is-metric="isMetric" chartHeight="150px" chartWidth="100%"
        :legendSettings="{ visible: true, toggleVisibility: true, position: 'Bottom' }"
        :data-transmissions="dataTransmissions || []" :most-recent-transmission="mostRecentTransmission"
        :road-conditions-history="forecasts?.RoadConditionsHistory || []"
        :forecast-data="forecasts?.ForecastData || []"></sensor-chart>
    </div>
    <sensor-history-section :device-id="sensorId" :history="history.transmissions || []" :requests="requests"
      :road-conditions-history="history.roadConditions || []" :is-metric="isMetric"></sensor-history-section>
  </div>
</template>

<script>
import Vue, { ref } from 'vue';
import moment from 'moment-timezone';
import { mapStores } from 'pinia';
import { useElementSize } from '@vueuse/core';

import { useBaseStore, useGroupsStore, useHistoryStore, useLocationsStore } from '../js/store';
import app from '../js/app';
import { convertWindSpeed, formatWindDirection, parseWindSpeedUnit, isTabletScreen } from '../js/utils';

import sensorTools from './sensorTools';
import sensorChart from './sensorChart';
import sensorDetails from './sensorDetails';
import sensorHistorySection from './sensorHistorySection';
import fallbackImage from './fallbackImageComponent';
import ForecastTiles from "./forecastTiles";
import imageWithOverlay from './imageWithOverlay';
import forecastIcons from './forecastIcons';

import missingImage from '../images/missing-image.png';

const DEFAULT_STATUS = {
  defrost: false,
  event: false,
  work: false,
  photo: false
}

export default {
  name: 'sensors-view',
  props: ['sensorId'],
  emits: [
    'handle-go-back'
    ,],
  components: {
    sensorTools,
    sensorChart,
    sensorDetails,
    sensorHistorySection,
    fallbackImage,
    ForecastTiles,
    imageWithOverlay,
    forecastIcons,
  },
  setup: () => {
    const sensorWindow = ref(null);
    const { width, height } = useElementSize(sensorWindow);
    return {
      width,
      height,
      sensorWindow,
    };
  },
  data: function () {
    return {
      additionalImageLimit: 14,
      transmissionIndex: 0,
      stacked: false,
      refreshTimer: null,

      missingImage,

      status: { ...DEFAULT_STATUS },
    };
  },
  methods: {
    convertWindSpeed,
    formatWindDirection,
    parseWindSpeedUnit,
    handleImageClick(e) {
      this.transmissionIndex = parseInt(e.target.dataset.index, 10)
    },
    async getSensorById() {
      // If we don't have a sensor ID, we can't do anything, redirect to dashboard
      if (!this.sensorId) {
        this.$router.push({
          name: 'sensors',
          query: {
            ...this.$route.query,
            sensorId: undefined
          }
        });
        return;
      }
      if (this.groupsStore.groups.length === 0 || !this.groupsStore.selectedGroup) {
        const groupId = this.$route.query.groupId;
        await this.groupsStore.selectGroupFromPreferences(groupId);
      }
      // Get the image transmissions for the sensor
      await this.locationsStore.getImageTransmissions(this.sensorId);
      // We should retrieve images if we have none
      // Current images gets images for all sensors in the group
      if (!this.locationsStore.images[this.sensorId]) {
        await this.locationsStore.getLatestImages();
      }
      // Once we have a way to include images in the data transmissions
      await this.locationsStore.getDataTransmissions([this.sensorId]);
      // We should also retrieve forecasts either way so they are fresh
      await this.locationsStore.getForecasts([this.sensorId]);

      await this.getHistory();
      await this.getPhotos();
      await this.getRequests();
    },
    async getHistory() {
      if (!this.sensorId) {
        return;
      }
      await this.historyStore.getHistory(this.sensorId);
    },
    async getPhotos() {
      if (!this.sensorId) {
        return;
      }
      await this.historyStore.getPhotosMetadata(this.sensorId);
      await this.historyStore.getPhotosPage(this.sensorId);
    },
    async getRequests() {
      if (!this.sensorId) {
        return;
      }
      await this.historyStore.getRequests(this.sensorId);
    },
    previousTransmission() {
      if (this.imageTransmissions && this.transmissionIndex < this.imageTransmissions.length - 1) {
        this.transmissionIndex++;
      } else {
        this.transmissionIndex = 0;
      }
    },
    nextTransmission() {
      if (this.transmissionIndex > 0) {
        this.transmissionIndex--;
      } else {
        this.transmissionIndex = this.imageTransmissions.length - 1;
      }
    },
    handleStatusUpdate(key, status) {
      this.status[key] = status;
    },
  },
  computed: {
    ...mapStores(useBaseStore, useGroupsStore, useLocationsStore, useHistoryStore),
    userHasFullAccess() {
      return true; // Add logic to determine user access
    },
    sensor() {
      const found = this.locationsStore.devices.find(l => {
        return parseInt(l.ID, 10) === parseInt(this.sensorId, 10)
      });
      if (!found) {
        return {};
      }
      return found
    },
    isMetric() {
      return this.sensor?.GroupEmbedded?.IsMetric ? true : false;
    },
    temparatureUnit() {
      return this.sensor?.GroupEmbedded?.TemperatureUnits === "Fahrenheit" ? "°F" : "°C";
    },
    dataTransmissions() {
      // These should already be in the correct order
      return this.locationsStore.dataTransmissions[this.sensorId] || [];
    },
    mostRecentTransmission() {
      return this.locationsStore.mostRecentTransmissions[this.sensorId] || {};
    },
    forecasts() {
      return this.locationsStore.forecasts[this.sensorId] || {};
    },
    imageTransmissions() {
      // Server is returning some images that are not near transmission datetime
      const iT = this.locationsStore.imageTransmissions[this.sensorId] || [];
      return iT.filter(r => {
        if (r.ImageCaptureDateTimeUTC && r.TransmissionDateTimeUTC) {
          // Check to make sure there isn't more than an hour between them
          const imageDate = moment.utc(r.ImageCaptureDateTimeUTC)
          const transmissionDate = moment.utc(r.TransmissionDateTimeUTC)
          const diff = imageDate.diff(transmissionDate, 'minutes')
          if (diff > 60) {
            return false
          } else {
            return true
          }
        } else {
          return false
        }
      })
    },
    selectedReading() {
      if (!this.imageTransmissions?.length) {
        return {
          ProcessedCameraImageURL: this.missingImage,
        };
      }
      const nonForcastReadings = this.imageTransmissions.filter(r => !r.IsForecast);
      const selected = nonForcastReadings[this.transmissionIndex];
      const found = this.locationsStore.forecasts[this.sensorId]?.RoadConditionsHistory?.find(history => {
        const historyDate = moment.utc(history.TransmissionDateTimeUTC);
        if (historyDate.diff(moment.utc(selected.TransmissionDateTimeUTC), 'minutes') < 5) {
          return history;
        }
      });
      if (!found) {
        return {
          ...selected,
        }
      }
      return {
        ...selected,
        WindSpeed: convertWindSpeed(found.WindSpeed, this.isMetric),
        WindDirection: formatWindDirection(found.WindDirection),
      }
    },
    additionalImages() {
      if (!this.imageTransmissions.length) {
        return Array(this.additionalImageLimit).fill({});
      }
      const nonForcastReadings = this.imageTransmissions.filter(r => !r.IsForecast && r.ProcessedCameraImageURL);
      const startIndex = this.transmissionIndex;
      const endIndex = this.transmissionIndex + this.additionalImageLimit > nonForcastReadings.length ? nonForcastReadings.length : this.transmissionIndex + this.additionalImageLimit;

      const formattedImages = nonForcastReadings.map(r => ({
        index: nonForcastReadings.findIndex((x) => x.ProcessedCameraImageURL === r.ProcessedCameraImageURL),
        url: r.ProcessedCameraImageURL,
        text: r.chartDate,
      }));

      let images = formattedImages.slice(startIndex, endIndex);

      // create a carousel effect for the thumbnails
      if (images.length < nonForcastReadings.length) {
        // append images
        const remainingImages = formattedImages.slice(0, startIndex);
        images = images.concat(remainingImages);

        // limit images to 14
        images = images.slice(0, this.additionalImageLimit);
      }

      return images;
    },
    history() {
      return {
        transmissions: this.historyStore.transmissions[this.sensorId] || [],
        roadConditions: this.historyStore.roadConditionsHistory[this.sensorId] || []
      };
    },
    requests() {
      return this.historyStore.requests[this.sensorId] || [];
    },
    isTablet() {
      return isTabletScreen(true)
    },
  },
  created() {
    window.title = 'Sensor View | Frost Solutions'
  },
  watch: {
    sensorId(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.getSensorById();
        this.transmissionIndex = 0;
        this.status = { ...DEFAULT_STATUS };
      }
    },
    width(newValue) {
      this.stacked = newValue < 700;
    },
  },
  mounted() {
    this.getSensorById();
    this.refreshTimer = setInterval(() => {
      this.getSensorById();
    }, 1000 * app.DASHBOARD_REFRESH_INTERVAL_SECONDS);

  },
  beforeDestroy() {
    clearInterval(this.refreshTimer);
  }
};
</script>
