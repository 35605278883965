<template>
  <div class="forecast-section">
    <div class="mb-2">
      <span class="uppercase mr-2">Forecast:</span>
      <span class="bold">{{ parsePrecipAccumulation(forecast?.ForecastPrecipSummary, isMetric) || '-' }}</span>
    </div>
    <div class="forecast-blocks">
      <div class="forecast-block">
        <img class="forecast-tile-icon"
             :src="parseWeatherConditionIcon(forecast?.ForecastWeather, hours)" />
        <div class="forecast-text-block">
          <span>{{ parseWeatherConditionText(forecast?.ForecastWeather) }} </span>
          <span>{{ weatherHours }}</span>
        </div>
      </div>
      <div class="forecast-block">
        <img class="forecast-tile-icon"
             :src="parseRoadConditionIcon(forecast?.RoadCondition?.RoadCondition)" />
        <div class="forecast-text-block">
          <span>{{ parseRoadConditionText(forecast?.RoadCondition?.RoadCondition) }} </span>
          <span>{{ roadHours }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";

import {
  parseRoadConditionIcon,
  parseRoadTextConditionIcon,
  parseWeatherConditionIcon,
  parseWeatherConditionText,
  parseTextWeatherConditionIcon,
  parseRoadConditionText,
  parsePrecipAccumulation,
  getHours,
  parseHoursMinHover
} from '../js/utils';

export default {
  name: "forecast-icons",
  props: ["forecast", "timeZone", 'isMetric'],
  data: function () {
    return {
      cssClass: 'customtip',
    };
  },

  computed: {
    hours() {
      return getHours(this.forecast?.WeatherCondtion?.ForecastDateTimeUTC, this.timeZone);
    },
    weatherHours() {
      return parseHoursMinHover(this.forecast?.WeatherCondition?.ForecastMinutes).text;
    },
    roadHours() {
      return parseHoursMinHover(this.forecast?.RoadCondition?.ForecastMinutes).text;
    },
  },
  methods: {
    parseRoadConditionIcon,
    parseRoadTextConditionIcon,
    parseWeatherConditionIcon,
    parseTextWeatherConditionIcon,
    parseWeatherConditionText,
    parseRoadConditionText,
    parseHoursMinHover,
    parsePrecipAccumulation,
    getHours,
  },
};
</script>
