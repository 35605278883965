<template>
  <div style="padding: 20px 5px;">
    <div v-for="(location, $index) in zone.Locations"
         :key="$index"
         class="app-sensor-container dm-pointer dm-flex-column"
         @click="(e) => openSensor(location)"
         @touchstart="loadMore($index)">
      <sidebar-sensor :location="location"
                      :most-recent-transmission="locationsStore.mostRecentTransmissions[location.ID]"
                      :data-transmissions="locationsStore.dataTransmissions[location.ID] || []"
                      :forecast="locationsStore.forecasts[location.ID]"
                      :images="locationsStore.images[location.ID]" />
    </div>

    <div class="dm-padding-top-sm dm-text-center"
         v-if="loading">
      <pulse-loader :loading="loading"></pulse-loader>
    </div>
  </div>
</template>

<script>
import { mapStores, mapState } from "pinia";
import PulseLoader from 'vue-spinner/src/PulseLoader'

import { useBaseStore, useLocationsStore } from "../js/store";

import SidebarSensor from "./sidebarSensorItem";

export default {
  name: "sidebar-zone-location",
  props: ["zone"],
  components: {
    SidebarSensor
  },

  data: function () {
    return {
      loading: false,
    };
  },
  methods: {
    openSensor(location) {
      this.$router.push({ query: { ...this.$route.query, sensorId: location.ID } })
    },
    openNotifs() {
      return [];
    },
    openConnectionIssueModal() {
      DM.alertShow(
        "We are experiencing difficulty connecting to this RWIS device. This difficulty is usually intermittent. If it persists, please contact Frost Solutions."
      );
    },
    onCustomOpen(refs, args) {
      const target = args.target.closest('.tooltip-cont-mobile');
      if (target.getAttribute('data-tooltip-id')) {
        refs[0].close();
      } else {
        eventBus.$emit('removeForecastTooltip');
        refs[0].open(target);
      }
    },
  },
  computed: {
    ...mapStores(useBaseStore, useLocationsStore),
    ...mapState(useLocationsStore, ["images", "transmissions"]),
  },
};
</script>

<style>
.forecast-legend {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  padding-bottom: 3px;
}

.forecast-legend>.forecast-label {
  padding: 2px;
}

.forecast-legend .forecast-icon {
  width: 13px;
  height: 13px;
  background: #EBEBEB;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  outline: none;
  position: relative;
  cursor: pointer;
}

.forecast-legend .fa-info-circle {
  font-size: 15px;
}

.forecast-tiles .tiles-title {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
}

.forecast-tiles .forecast-container {
  flex: 1;
  min-height: 120px;
  max-height: 150px;
  background: #FBF9FB;
  border: 1px solid #EFF1F5;
  border-radius: 12px;
  text-align: center;
}

.forecast-tiles .forecast-tile-icon {
  padding-left: 5px;
  padding-right: 5px;
  height: 30px;
  width: 50px;
}

.forecast-container .forecast-time {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  padding-left: 2px;
}

.forecast-container .accumulation-text {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  padding: 10px 5px;
  padding-top: 7px;
}

.customtip.e-tooltip-wrap.e-popup {
  width: 147px !important;
  /* height: 140px !important; */
  border: 1px;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.customtip.e-tooltip-wrap .e-tip-content {
  color: black;
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  padding-bottom: 10px;
  background: #FFFFFF !important;
}

.customtip.e-tooltip-wrap .e-arrow-tip.e-tip-bottom {
  height: 20px;
  width: 12px;
}

.customtip.e-tooltip-wrap .e-arrow-tip.e-tip-top {
  height: 20px;
  width: 12px;
}

.customtip.e-tooltip-wrap .e-arrow-tip.e-tip-left {
  height: 12px;
  width: 20px;
}

.customtip.e-tooltip-wrap .e-arrow-tip.e-tip-right {
  height: 12px;
  width: 20px;
}

.customtip.e-tooltip-wrap .e-arrow-tip-outer.e-tip-bottom {
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 20px solid #FFFFFF;
}

.app-sensor-container {
  margin-bottom: 10px;
  padding-bottom: 10px;
}

.e-chart {
  overflow: inherit !important;
}

.e-tooltip {
  z-index: 2 !important;
}

.admin-sensor-data {
  z-index: 1;
}
</style>