var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dm-page-container dm-flex-column dm-height-100"},[_c('dm-table',{ref:"grdGroups",attrs:{"table":{
            grdTableItems: _vm.grdGroupsItems,
            grdTableRowOnClick: _vm.grdGroupsRowOnClick,
            grdTableActionOnClick: _vm.grdGroupsActionOnClick,
            grdTableSortBy: _vm.grdGroupsSortBy,
            grdTableFields: _vm.grdGroupsFields,
        },"allowPaging":true}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }