<template src="./qrCodeScanModal.html"></template>

<script>
    import dmModalMixin from '../../../dmFramework/dmJS/dmModalMixin'
    import {QrcodeStream, QrcodeDropZone, QrcodeCapture} from 'vue-qrcode-reader'

    export default {
        name: 'qr-code-scan-modal',
        mixins: [dmModalMixin],
        components: {
            QrcodeStream,
            QrcodeDropZone,
            QrcodeCapture
        },
        props: [
            "modalData",
        ],
        data: function () {
            return {
                modalName: 'qrCodeScanModal' + DM.getRandomGuid(),
                lblTitle: 'Scan QR Code',
                emailAddress: '',
            }
        },
        methods: {
            btnCancelOnClick() {
                this.modalRef.hide();
            },
            onDecode(decodedString) {
                // alert(decodedString);
                this.modalData.callback(decodedString);
                this.modalRef.hide();
            },
            async onInit(promise) {
                // show loading indicator
                try {
                    const {capabilities} = await promise
                    // alert(JSON.stringify(capabilities));
                    // successfully initialized
                } catch (error) {
                    if (error.name === 'NotAllowedError') {
                        // user denied camera access permisson
                        alert('user denied camera access permisson');
                    } else if (error.name === 'NotFoundError') {
                        // no suitable camera device installed
                    } else if (error.name === 'NotSupportedError') {
                        // page is not served over HTTPS (or localhost)
                    } else if (error.name === 'NotReadableError') {
                        // maybe camera is already in use
                    } else if (error.name === 'OverconstrainedError') {
                        // did you requested the front camera although there is none?
                    } else if (error.name === 'StreamApiNotSupportedError') {
                        // browser seems to be lacking features
                    }
                } finally {
                    // hide loading indicator
                }
            }
        },
        created() {

        },
        mounted() {

        }
    }
</script>
