<template>
  <div class="drop-down-filter forecast-limit dm-flex-none">
    <div class="e-input-group">
      <ejs-dropdownlist :dataSource="intervalOptions"
                        :fields="fields"
                        :index="0"
                        :change="handleChangeInterval"
                        :value="baseStore.preferences.chartLimit"
                        floatLabelType="Auto"
                        placeholder="Forecast"></ejs-dropdownlist>
    </div>
  </div>
</template>

<script>
import { mapStores } from "pinia";
import { useBaseStore } from "../js/store";

export default {
  name: 'chart-limit-selector',
  data() {
    return {
      intervalOptions: [
        { text: '72 hrs', value: 72 },
        { text: '48 hrs', value: 48 },
        { text: '24 hrs', value: 24 },
      ],
      fields: { text: 'text', value: 'value' },
    }
  },
  methods: {
    handleChangeInterval(val) {
      this.baseStore.setPreferences(
        {
          ...this.baseStore.preferences,
          chartLimit: val.value
        }
      )
    }
  },
  computed: {
    ...mapStores(useBaseStore),
  }
}
</script>

<style>
.forecast-limit {
  margin-left: 0;
  width: 90px;
}

.forecast-limit .e-input-group {
  margin-bottom: 0;
  border-radius: 4px !important;
}
</style>