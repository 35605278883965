<template src="./usersPasswordEditModal.html"></template>

<script>
    import { mapStores } from 'pinia';
    import { useBaseStore } from '../../js/store';

    import dmModalMixin from '../../../dmFramework/dmJS/dmModalMixin'

    export default {
        name: 'users-password-edit-modal',
        mixins: [dmModalMixin],
        props: [
            "modalData",
        ],
        data: function () {
            return {
                modalName: 'usersPasswordEditModal' + DM.getRandomGuid(),
                lblTitle: 'Change Password',
                currentPassword: '',
                newPassword: '',
                newPasswordConfirm: '',
            }
        },
        computed: {
            ...mapStores(useBaseStore)
        },
        methods: {
            savePassword() {
                return DM.http({
                    method: "POST",
                    url: `/users/${this.baseStore.userID}/password`,
                    data: {
                        OldPassword: this.currentPassword,
                        NewPassword: this.newPassword,
                    }
                });
            },
            btnSaveOnClick() {
                if(this.newPassword !== this.newPasswordConfirm) {
                    DM.alertShow('New Password and Confirm New Password do not match.', 'Error');
                    return;
                }

                this.savePassword()
                    .then((response) => {
                        if(this.modalData.callback) {
                            this.modalData.callback(response);
                        }
                        this.modalRef.hide();
                    });
            },
            btnCancelOnClick() {
                this.modalRef.hide();
            },
        },
        created() { },
        mounted() { }
    }
</script>