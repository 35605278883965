<template>
  <div class="dm-flex-column dm-margin-sm">
    <div class="select-graph-wrapper">
      <ejs-multiselect ref="ms"
                       v-model="graphs"
                       :dataSource="graphOptions"
                       :mode="'CheckBox'"
                       placeholder="Select Graphs"
                       floatLabelType="Auto"
                       @select="selectedGraphsOnChange"
                       @removed="selectedGraphsOnChange" />
    </div>

    <div class="dm-flex-column dm-padding-top-md">
      <ejs-chart width="100%"
                 height="380px"
                 border="{color: '#FFF'}"
                 :key="componentKey"
                 :primaryXAxis="primaryXAxis"
                 :crosshair="crosshair"
                 :indicators="indicators"
                 :axes="axes"
                 :palettes="palettes"
                 :tooltip="tooltip">
        <e-series-collection>
          <e-series v-if="selectedGraphs.map(g => g.value).includes('Surface Temp')"
                    :dataSource="surfaceTempData"
                    type="Line"
                    xName="chartDate"
                    yName="SurfaceTemp"
                    name="Reading"
                    yAxisName="primaryYAxisTemp"
                    width="2" />
          <e-series v-if="selectedGraphs.map(g => g.value).includes('Surface Temp')"
                    :dataSource="surfaceTempData"
                    type="Scatter"
                    xName="chartDate"
                    yName="SurfaceTemp"
                    name="Reading"
                    opacity="1"
                    yAxisName="primaryYAxisTemp"
                    :marker="marker"
                    :enableTooltip="false" />

          <e-series v-if="selectedGraphs.map(g => g.value).includes('Air Temp')"
                    :dataSource="airTempData"
                    type="Line"
                    xName="chartDate"
                    yName="AirTemp"
                    name="Reading"
                    yAxisName="primaryYAxisTemp"
                    width="2" />
          <e-series v-if="selectedGraphs.map(g => g.value).includes('Air Temp')"
                    :dataSource="airTempData"
                    type="Scatter"
                    xName="chartDate"
                    yName="AirTemp"
                    name="Reading"
                    opacity="1"
                    yAxisName="primaryYAxisTemp"
                    :marker="marker"
                    :enableTooltip="false" />

          <e-series v-if="selectedGraphs.map(g => g.value).includes('Dew Point')"
                    :dataSource="dewPointData"
                    type="Line"
                    xName="chartDate"
                    yName="DewPoint"
                    name="Reading"
                    yAxisName="primaryYAxisTemp"
                    width="2" />
          <e-series v-if="selectedGraphs.map(g => g.value).includes('Dew Point')"
                    :dataSource="dewPointData"
                    type="Scatter"
                    xName="chartDate"
                    yName="DewPoint"
                    name="Reading"
                    opacity="1"
                    yAxisName="primaryYAxisTemp"
                    :marker="marker"
                    :enableTooltip="false" />

          <e-series v-if="selectedGraphs.map(g => g.value).includes('Humidity')"
                    :dataSource="humidityData"
                    type="Line"
                    xName="chartDate"
                    yName="Humidity"
                    name="Reading"
                    yAxisName="primaryYAxisHumidity"
                    width="2" />
          <e-series v-if="selectedGraphs.map(g => g.value).includes('Humidity')"
                    :dataSource="humidityData"
                    type="Scatter"
                    xName="chartDate"
                    yName="Humidity"
                    name="Reading"
                    opacity="1"
                    yAxisName="primaryYAxisHumidity"
                    :marker="marker"
                    :enableTooltip="false" />

          <e-series v-if="showDiagnosticCodes"
                    :dataSource="history.filter(r => r.DiagnosticCodeValue)"
                    type="Line"
                    xName="chartDate"
                    yName="DiagnosticCodeValue"
                    name="Reading"
                    yAxisName="primaryYAxisDiagnosticCode"
                    width="2" />
        </e-series-collection>
      </ejs-chart>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapStores, mapState } from 'pinia';
import { useBaseStore, useHistoryStore, useLocationsStore } from '../js/store';
import {
  CandleSeries,
  Category,
  Tooltip,
  DateTime,
  Zoom,
  Logarithmic,
  Crosshair,
  LineSeries,
  AccumulationDistributionIndicator,
  StripLine,
  ColumnSeries,
  ScatterSeries,
} from "@syncfusion/ej2-vue-charts";
import hasPhoto from '../images/svg/has-photo.svg';
import exportPhoto from '../images/svg/export-photo.svg';
import exportWhite from '../images/svg/export-white.svg';
import exportText from '../images/svg/export-text.svg';
import photoInfo from '../images/svg/photo.svg';
import infoIcon from '../images/svg/info.svg';

export default {
  name: 'sensors-history-charts',
  props: [
    "history",
  ],
  provide: {
    chart: [
      CandleSeries,
      Category,
      LineSeries,
      Tooltip,
      DateTime,
      StripLine,
      Zoom,
      Logarithmic,
      Crosshair,
      AccumulationDistributionIndicator,
      ColumnSeries,
      ScatterSeries
    ]
  },
  data: function () {
    return {
      infoIcon: infoIcon,
      photoInfo: photoInfo,
      exportText: exportText,
      exportWhite: exportWhite,
      hasPhoto: hasPhoto,
      exportPhoto: exportPhoto,
      lblTitle: '',
      componentKey: 0,
      primaryYAxisTemp: {
        visible: true,
        opposedPosition: true,
        labelFormat: '{value}',
      },
      primaryYAxisHumidity: {
        visible: true,
        labelFormat: '{value}',
      },
      primaryYAxisDiagnosticCode: {
        visible: true,
        labelFormat: '{value}',
      },
      marker: {
        height: 6,
        width: 6,
        fill: '#319cf4',
        dataLabel: {
          visible: false
        }
      },
      indicators: [{
        type: 'AccumulationDistribution',
        field: 'Close',
        yAxisName: 'y',
        fill: '#6063ff',
        period: 3,
        animation: { enable: true }
      }],
      crosshair: { enable: true, lineType: 'Vertical' },
      tooltip: {
        enable: true,
        shared: true,
        header: '<b>${point.x}</b>',
        format: '<b>${point.y}</b>',
      },
      grdActions: [
        {
          text: 'Export'
        },
      ],
      graphs: ['Surface Temp'],
      selectedGraphs: [
        {
          text: 'Surface Temp',
          value: 'Surface Temp'
        }
      ],
      graphOptions: [
        {
          text: 'Surface Temp',
          value: 'Surface Temp',
          color: 'gray',
        },
        {
          text: 'Air Temp',
          value: 'Air Temp',
          color: 'blue',
        },
        {
          text: 'Dew Point',
          value: 'Dew Point',
          color: 'green',
        },
        {
          text: 'Humidity',
          value: 'Humidity',
          color: 'red',
        }
      ],
      axes: [
        {
          visible: true,
          opposedPosition: true,
          labelFormat: '{value}°',
          name: 'primaryYAxisTemp'
        },
        {
          visible: true,
          labelFormat: '{value}%',
          name: 'primaryYAxisHumidity'
        },
        {
          visible: true,
          labelFormat: '{value}',
          name: 'primaryYAxisDiagnosticCode'
        },
      ],
      showDiagnosticCodes: false,
    }
  },
  methods: {
    mnuChartsOnClick() { },
    mnuReadingsOnClick() { },
    selectedGraphsOnChange($event) {
      if ($event.name === 'select') {
        this.selectedGraphs.push($event.itemData);
      }

      if ($event.name === 'removed') {
        this.selectedGraphs = this.selectedGraphs.filter(h => h.value !== $event.itemData.value);
      }

      this.showDiagnosticCodes = false;

      this.componentKey++;
    },
  },
  computed: {
    ...mapStores(useBaseStore, useLocationsStore),
    ...mapState(useHistoryStore, ['start', 'end', 'interval']),
    sensor() {
      return {};
    },
    palettes() {
      const colors = [];

      this.graphOptions.forEach(graph => {
        let selectedGraph = this.selectedGraphs.filter(o => o.text === graph.text)[0];
        if (selectedGraph) {
          colors.push(graph.color);
          colors.push(graph.color);
        }
      });

      return colors;
    },
    primaryXAxis() {
      let stripLine = {};

      if (this.sensorHistoryData) {
        const sensorReadings = this.sensorHistoryData.DataTransmissions;
        for (let i = 0; i < sensorReadings.length; i++) {
          if (sensorReadings[i].IsForecast) {
            stripLine = {
              start: sensorReadings[i].chartDate,
              end: sensorReadings[sensorReadings.length - 1].chartDate,
              color: '#000',
              visible: true,
              opacity: '0.08'
            };

            break;
          }
        }
      }

      return {
        visible: true,
        color: '#5fb1f6',
        valueType: 'DateTime',
        labelFormat: 'MMM d h:mm a',
        intervalType: 'Hours',
        stripLines: [stripLine]
      }
    },
    surfaceTempData() {
      return this.history.filter(r => r.SurfaceTemp && !r.IsForecast).map(r => {
        return {
          chartDate: r.chartDate,
          SurfaceTemp: r.SurfaceTemp
        };
      });
    },
    airTempData() {
      return this.history.filter(r => r.AirTemp && !r.IsForecast).map(r => {
        return {
          chartDate: r.chartDate,
          AirTemp: r.AirTemp
        };
      });
    },
    dewPointData() {
      return this.history.filter(r => r.DewPoint && !r.IsForecast).map(r => {
        return {
          chartDate: r.chartDate,
          DewPoint: r.DewPoint
        };
      });
    },
    humidityData() {
      return this.history.filter(r => r.Humidity && !r.IsForecast).map(r => {
        return {
          chartDate: r.chartDate,
          Humidity: r.Humidity
        };
      })
    },
    isUserSuperAdmin() {
      return this.baseStore.user?.IsSuperAdmin;
    },
    grdSensorReadingsItems() {
      return this.history.filter(r => !r.IsForecast)
    },
  },
  watch: {
    showDiagnosticCodes: function (newVal) {
      if (newVal) {
        this.graphs = this.graphs.filter(g => g === 'Diagnostic Code');
        this.selectedGraphs = [];
      }

      this.componentKey++;
    },
  },
  created() {
  },
  mounted() {
  },
}
</script>

<style>
.e-btn-icon.e-image {
  background: url(../images/svg/export-photo.svg);
  background-repeat: repeat-y;
  width: 10px;
  height: 10px;
}

.export-style.e-dropdown-popup {
  border: 1px solid #EFF2F5;
  box-shadow: 40px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.history-tabs {
  background: none !important;
}

.export-style.e-dropdown-popup ul {
  min-width: 57px !important;
}

.export-style li.e-menu-item {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #6D6E71;
}

.export-style li.e-menu-item.e-selected {
  background: #EFF2F5 !important;
}

.custom-date .e-float-input.e-control-wrapper {
  background: #FFF !important;
}

button.export-button {
  display: flex;
  gap: 10px;
  color: white;
  padding: 9px 24px;
  background-color: #319CF4;
  border-radius: 8px;
  margin-bottom: 5px;
}

.history-table .e-grid {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior: none;
}

.select-graph-wrapper {
  display: flex;
  max-width: 400px;
}
</style>