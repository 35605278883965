<template>
	<div>
		<div v-for="(zone) in group.Zones">
			<h4 class="zone-header">
				{{ zone.ZoneName === 'No Zone' ? '' : zone.ZoneName }}
			</h4>
			<sidebar-zone-location :zone="zone"></sidebar-zone-location>
		</div>
	</div>
</template>

<script>
import Vue from 'vue'
import { mapStores } from 'pinia'
import { useBaseStore } from '../js/store'
import SidebarZoneLocation from './sidebarZoneLocation'

export default {
	name: 'sidebar-zone',
	props: [
		'group'
	],
	components: {
		SidebarZoneLocation
	},
	computed: {
		...mapStores(useBaseStore),
	},
	methods: {
		updatePreferences(zone) {
			if (this.baseStore.user.Preferences) {
				let groupPreference = this.baseStore.user.Preferences.SensorMapViewDisplay.filter(p => p.Group.ID === this.group.GroupID)[0];
				if (groupPreference) {
					let zonePreference = groupPreference.Zones.filter(z => z.Name === zone.ZoneName)[0];
					if (zonePreference) {
						zonePreference.DisplayAsDefault = zone.showZone;
					} else {
						groupPreference.Zones.push({
							Name: zone.ZoneName,
							DisplayAsDefault: zone.showZone
						});
					}
				} else {
					let preference = {
						Group: {
							ID: this.group.GroupID,
							Name: this.group.GroupName,
							DisplayAsDefault: true
						},
						Zones: [
							{
								Name: zone.ZoneName,
								DisplayAsDefault: zone.showZone
							}
						]
					};

					this.baseStore.user.Preferences.SensorMapViewDisplay.push(preference);
				}
			} else {
				this.baseStore.user.Preferences = {
					SensorMapViewDisplay: [
						{
							Group: {
								ID: this.group.GroupID,
								Name: this.group.GroupName,
								DisplayAsDefault: true
							},
							Zones: [
								{
									Name: zone.ZoneName,
									DisplayAsDefault: zone.showZone
								}
							]
						}
					]
				};
			}

			eventBus.$emit('updateMapByZonePreferences', this.group.GroupID, zone);

			DM.http({
				method: 'PUT',
				url: `/users/${this.baseStore.userID}/preferences`,
				data: this.baseStore.user.Preferences
			}, true);
		}
	},
	created() { },
	mounted() { },
	beforeDestroy() {
		eventBus.$off('updateMapByZonePreferences');
	}
}
</script>
<style>
.zone-header {
	margin: 0;
	padding: 0 10px;
	font-size: 14px;
	font-weight: 600;
}
</style>