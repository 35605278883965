<template>
  <div class="sensor-tools" :class="props.wrapperClasses">
    <tool-button v-if="!workStatus" label="Begin Work"
      content="Capture readings & photo before you begin work, and then again after for proof of work." :icon="workIcon"
      :action="handleStartWorkClick"></tool-button>
    <tool-button v-else="workStatus" label="End Work"
      content="Capture readings & photo before you begin work, and then again after for proof of work." :icon="workIcon"
      :action="handleEndWorkClick"></tool-button>
    <tool-button label="Request Photo"
      content="Request an on-demand photo. Images typically take a few minutes to load." :icon="photoIcon"
      :action="handleRequestPhotoClick"></tool-button>
    <tool-button label="Start Event"
      content="Storm Mode requests photos every 20 mins for 4 hrs. You'll only need this outside of the winter or if you haven't installed your upgraded camera."
      :icon="!status.event ? eventIcon : successIcon" :action="handleEventClick"></tool-button>
    <tool-button label="Run Defroster"
      content="The Defroster heats up the camera window and takes a photo after the defroster runs. Caution: Frequent defrosting will impact battery life."
      :icon="!status.defrost ? defrosterIcon : successIcon" :action="handleDefrostClick"></tool-button>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, computed } from 'vue';

import ToolButton from './toolButton';

import { useLocationsStore } from '../js/store';

import workIcon from '../images/svg/work.svg';
import photoIcon from '../images/svg/photo.svg';
import eventIcon from '../images/svg/event.svg';
import defrosterIcon from '../images/svg/defroster.svg';
import successIcon from '../images/svg/success.svg';

const props = defineProps([
  'status',
  'sensorId',
  'userHasFullAccess',
  'viewFromHistory',
  'wrapperClasses'
]);

const emit = defineEmits([
  'handle-status-update',
]);

const locationsStore = useLocationsStore();

const workStatus = computed(() => {
  const st = locationsStore.startedWorkDeviceIds.includes(props.sensorId);
  console.log('workStatus', st);
  return st
});

function handleStartWorkClick() {
  locationsStore.startWork(props.sensorId).then(() => {
    emit('handle-status-update', 'work', true);
  })
}
function handleEndWorkClick() {
  locationsStore.endWork(props.sensorId).then(() => {
    emit('handle-status-update', 'work', false);
  })
}
function handleDefrostClick() {
  locationsStore.triggerDefrost(props.sensorId).then(() => {
    emit('handle-status-update', 'defrost', true);
  })
}
function handleEventClick() {
  locationsStore.startEvent(props.sensorId).then(() => {
    emit('handle-status-update', 'event', true);
  })
}
function handleRequestPhotoClick() {
  locationsStore.requestPhoto(props.sensorId).then(() => {
    emit('handle-status-update', 'photo', true);
  })
}

</script>
