var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dm-flex-column dm-flex-1 dm-padding-sm photos-table",class:{ 'is-loading': _vm.isLoading }},[_c('h3',[_vm._v("Photos")]),_c('dm-table',{ref:"grdSensorPhotos",attrs:{"table":{
  grdTableItems: _vm.grdSensorReadingsItems,
  grdTableFields: _vm.grdSensorReadingsFields,
},"allowPaging":false}}),_c('div',{staticClass:"e-gridpager e-control e-pager e-lib"},[_c('div',{staticClass:"e-pagercontainer",attrs:{"role":"navigation"}},[_c('div',{staticClass:"e-first e-icons e-icon-first",class:{
  'e-firstpagedisabled e-disable': _vm.currentPage <= 0,
  'e-firstpage e-pager-default': _vm.currentPage > 0
},attrs:{"title":"Go to first page","tabindex":"-1","index":"0"},on:{"click":function($event){return _vm.goToPage(1)}}}),_c('div',{staticClass:"e-prev e-icons e-icon-prev",class:{
  'e-prevpagedisabled e-disable': _vm.currentPage <= 0,
  'e-prevpage e-pager-default': _vm.currentPage > 0
},attrs:{"title":"Go to previous page","tabindex":"-1","index":_vm.currentPage - 1},on:{"click":function($event){return _vm.goToPage(_vm.currentPage)}}}),(_vm.previousPagerIndex)?_c('div',[_c('a',{staticClass:"e-link e-np e-spacing e-numericitem e-pager-default",attrs:{"title":"Go to previous pager","role":"link","aria-label":"Go to previous pager","tabindex":"-1","name":"Go to previous pager","href":"javascript:void(0);","index":_vm.previousPagerIndex},on:{"click":function($event){return _vm.goToPage(_vm.previousPagerIndex)}}},[_vm._v(" ... ")])]):_vm._e(),_c('div',{staticClass:"e-numericcontainer"},_vm._l((_vm.displayedPages),function(page){return _c('div',{key:page},[_c('a',{staticClass:"e-link e-numericitem e-spacing",class:{
  'e-currentitem e-active': _vm.currentPage === (page - 1)
},attrs:{"role":"link","tabindex":"-1","aria-label":`Goto Page ${page}`,"href":"javascript:void(0);","name":`Goto page${page}`,"index":page},on:{"click":function($event){return _vm.goToPage(page)}}},[_vm._v(" "+_vm._s(page)+" ")])])}),0),(_vm.nextPagerIndex)?_c('div',[_c('a',{staticClass:"e-link e-np e-spacing e-numericitem e-pager-default",attrs:{"title":"Go to next pager","role":"link","aria-label":"Go to next pager","tabindex":"-1","name":"Go to next pager","href":"javascript:void(0);","index":_vm.nextPagerIndex},on:{"click":function($event){return _vm.goToPage(_vm.nextPagerIndex)}}},[_vm._v(" ... ")])]):_vm._e(),_c('div',{staticClass:"e-next e-icons e-icon-next",class:{
  'e-nextpagedisabled e-disable': _vm.currentPage + 1 >= _vm.pagesAvailable,
  'e-nextpage e-pager-default': _vm.currentPage + 1 < _vm.pagesAvailable
},attrs:{"title":"Go to next page","tabindex":"-1","index":_vm.currentPage + 2},on:{"click":function($event){return _vm.goToPage(_vm.currentPage + 2)}}}),_c('div',{staticClass:"e-last e-icons e-icon-last",class:{
  'e-lastpagedisabled e-disable': _vm.currentPage + 1 >= _vm.pagesAvailable,
  'e-lastpage e-pager-default': _vm.currentPage + 1 < _vm.pagesAvailable
},attrs:{"title":"Go to last page","tabindex":"-1","index":_vm.pagesAvailable},on:{"click":function($event){return _vm.goToPage(_vm.pagesAvailable)}}})]),_c('div',{staticClass:"e-parentmsgbar"},[_c('span',{staticClass:"e-pagenomsg",staticStyle:{"text-align":"right"}},[_vm._v(_vm._s(_vm.currentPage + 1)+" of "+_vm._s(_vm.pagesAvailable)+" pages ")]),_c('span',{staticClass:"e-pagecountmsg",staticStyle:{"text-align":"right"}},[_vm._v("("+_vm._s(_vm.totalPhotos)+" items)")])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }