<template>
	<div>
		<div class="dm-flex-column dm-padding-right-sm dm-padding-left-sm dm-padding-top-sm">
			<div class="dm-flex-row">
				<fallback-image-component :images="displayedImages"
																	:alt="location.Name + ' preview'"
																	style="width:90px;height:80px;border-radius:12px;"
																	class="dm-flex-column dm-margin-right-sm app-background-contain" />
				<div class="app-sensor-details dm-flex-column dm-flex-1 dm-padding-right-sm">
					<div class="dm-flex-row">
						<span class="app-brand-green-color dm-bold dm-flex-1"
									style="font-size: 16px; line-height: 18px;">
							{{ location.Name }}
						</span>
						<div class="notif-container dm-position-relative">
							<sidebar-notification :sensor="location" />
						</div>
					</div>

					<div v-if="mostRecentTransmission"
							 class="dm-flex-row"
							 style="gap: 3px;">
						<div class="dm-flex-1">
							{{ mostRecentTransmission?.chartDate }}
						</div>
					</div>
					<div v-else
							 class="dm-flex-row"
							 style="gap: 3px;">
						<div class="dm-flex-1">
							-
						</div>
					</div>
					<div v-if="!location.IsOffline">
						<sensor-details :air-temp="mostRecentTransmission?.AirTemp"
														:surface-temp="mostRecentTransmission?.SurfaceTemp"
														:humidity="mostRecentTransmission?.Humidity"
														:dew-point="mostRecentTransmission?.DewPoint"
														:wind-speed="convertWindSpeed(forecast?.CurrentCondition?.WindSpeed, isMetric)"
														:wind-direction="formatWindDirection(forecast?.CurrentCondition?.WindDirection)"
														:temparatureunit="temparatureUnit"
														:windunit="parseWindSpeedUnit(location?.GroupEmbedded.IsMetric)"></sensor-details>
					</div>

					<div v-if="location.IsOffline">
						<br />
						<i class="fa fa-warning"
							 style="color: #c7c701"></i>
						<span @click.prevent.stop="openConnectionIssueModal"
									class="app-link">Connection Issue</span>
					</div>
				</div>
			</div>
		</div>

		<forecast-tiles :location="location"
										:current-condition="forecast?.CurrentCondition"
										:current-weather="forecast?.CurrentWeather"
										:road-condition="forecast?.RoadCondition"
										:precip-summary="forecast?.ForecastPrecipSummary"
										:weather-condition="forecast?.WeatherCondition"
										:time-zone="location?.TimeZone"
										:forecast-weather="forecast?.ForecastWeather"
										:is-tablet="isTablet"
										:is-metric="isMetric"
										:loading="locationsStore?.fetchingForecasts" />

		<!-- style="padding-bottom: 20px; margin-bottom: -30px" -->
		<div class="dm-flex-1 dm-flex-row dm-align-items-center dm-justify-content-center"
				 style="width: 100%; margin-top: 5px;">
			<sensor-chart-component :sensor="location"
															chartHeight="200px"
															:data-transmissions="dataTransmissions || []"
															:most-recent-transmission="mostRecentTransmission"
															:road-conditions-history="forecast?.RoadConditionsHistory || []"
															:forecast-data="forecast?.ForecastData || []" />
		</div>

		<div v-if="isUserSuperAdmin"
				 class="dm-text-center admin-sensor-data">
			<span class="dm-padding-right-md">Sensor: {{ location.SensorSerialNumber }}</span>
			<div class="forecast-legend">
				<input type="checkbox"
							 class="forecast-icon"
							 disabled="true" />
				<div class="forecast-label"><b>Forecast</b></div>
				<ejs-tooltip v-if="!isTablet"
										 class="tooltip-cont"
										 opensOn='Auto'
										 content="Forecast may take several days to generate initially, tuning is ongoing. Road conditions are projected assuming no treatment.">
					<i class="fa fa-solid fa-info-circle"></i>
				</ejs-tooltip>
				<ejs-tooltip v-if="isTablet"
										 class="tooltip-cont-mobile"
										 opensOn='custom'
										 :ref="'tooltip-' + location.ID"
										 content="Forecast may take several days to generate initially, tuning is ongoing. Road conditions are projected assuming no treatment.">
					<span @click="(e) => onCustomOpen($refs['tooltip-' + location.ID], e)"
								class="fa fa-solid fa-info-circle"></span>
					<!-- <i class="fa fa-solid fa-info-circle"></i> -->
				</ejs-tooltip>
			</div>
		</div>
	</div>
</template>

<script>
import { mapStores } from "pinia";
import { useBaseStore, useLocationsStore } from "../js/store";

import SensorChartComponent from "./sensorChartComponent";
import fallbackImageComponent from "./fallbackImageComponent";
import SensorDetails from './sensorDetails';
import SidebarNotification from "./notifications/SidebarNotification";
import ForecastTiles from "./forecastTiles";

import {
	parseWindSpeedUnit,
	parsePrecipAccumulation,
	parseRoadConditionIcon,
	parseHoursMinHover,
	convertWindSpeed,
	formatWindDirection,
	isTabletScreen,
} from "../js/utils";

export default {
	name: "sidebar-sensor-item",
	props: [
		"location",
		"images",
		"dataTransmissions",
		"mostRecentTransmission",
		"forecast",
	],
	components: {
		SensorChartComponent,
		fallbackImageComponent,
		SensorDetails,
		SidebarNotification,
		ForecastTiles
	},

	data: function () {
		return {
			zones: {},
			crosshair: {
				enable: true,
				lineType: "Vertical"
			},
			marker: {
				height: 6,
				width: 6,
				fill: '#319cf4',
				dataLabel: {
					visible: false
				}
			},
			tooltip: {
				enable: true,
				shared: true,
				header: "<b>${point.tooltip}</b>",
				format: "${series.name}: <b>${point.y}</b>",
			},
			cssClass: 'customtip',
			storeLocations: [],
			loading: false,
		};
	},
	methods: {
		convertWindSpeed,
		formatWindDirection,
		parseWindSpeedUnit,
		parsePrecipAccumulation,
		parseRoadConditionIcon,
		parseHoursMinHover,
		openNotifs() {
			return [];
		},
		openConnectionIssueModal() {
			DM.alertShow(
				"We are experiencing difficulty connecting to this RWIS device. This difficulty is usually intermittent. If it persists, please contact Frost Solutions."
			);
		},
		onCustomOpen(refs, args) {
			const target = args.target.closest('.tooltip-cont-mobile');
			if (target.getAttribute('data-tooltip-id')) {
				refs[0].close();
			} else {
				eventBus.$emit('removeForecastTooltip');
				refs[0].open(target);
			}
		},
	},
	computed: {
		...mapStores(useBaseStore, useLocationsStore),
		isUserSuperAdmin() {
			return this.baseStore.user?.IsSuperAdmin
		},
		isMobile() {
			return window.innerWidth < 666
		},
		isTablet() {
			return isTabletScreen(true)
		},
		isMetric() {
			return this.location?.GroupEmbedded?.IsMetric;
		},
		displayedImages() {
			const urls = this.images ?? [];
			return [...urls, this.mostRecentTransmission?.ProcessedCameraImageURL]
		},
		temparatureUnit() {
			return this.location?.GroupEmbedded?.TemperatureUnits == "Fahrenheit" ? "°F" : "°C";
		},
	},
	created() {
	},
	mounted() {
		eventBus.$on('removeForecastTooltip', () => {
			Object.values(this.$refs).forEach((ref) => {
				ref?.forEach((tooltip) => {
					if (typeof tooltip.close === 'function') {
						tooltip.close();
					}
				})
			});
		})
	},
	beforeDestroy() {
		eventBus.$off('removeForecastTooltip');
		delete this.zones
	}
};
</script>

<style>
.forecast-legend {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 5px;
	padding-bottom: 3px;
}

.forecast-legend>.forecast-label {
	padding: 2px;
}

.forecast-legend .forecast-icon {
	width: 13px;
	height: 13px;
	background: #EBEBEB;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	border: none;
	outline: none;
	position: relative;
	cursor: pointer;
}

.forecast-legend .fa-info-circle {
	font-size: 15px;
}

.forecast-tiles .tiles-title {
	font-family: 'Barlow';
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
}

.forecast-tiles .forecast-container {
	flex: 1;
	min-height: 120px;
	max-height: 150px;
	background: #FBF9FB;
	border: 1px solid #EFF1F5;
	border-radius: 12px;
	text-align: center;
}

.forecast-tiles .forecast-tile-icon {
	padding-left: 5px;
	padding-right: 5px;
	height: 30px;
	width: 50px;
}

.forecast-container .forecast-time {
	font-family: 'Barlow';
	font-style: normal;
	font-weight: 400;
	font-size: 10px;
	padding-left: 2px;
}

.forecast-container .accumulation-text {
	font-family: 'Barlow';
	font-style: normal;
	font-weight: 400;
	font-size: 10px;
	padding: 10px 5px;
	padding-top: 7px;
}

.customtip.e-tooltip-wrap.e-popup {
	width: 147px !important;
	/* height: 140px !important; */
	border: 1px;
	box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.1);
	border-radius: 8px;
}

.customtip.e-tooltip-wrap .e-tip-content {
	color: black;
	font-family: 'Barlow';
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	padding-bottom: 10px;
	background: #FFFFFF !important;
}

.customtip.e-tooltip-wrap .e-arrow-tip.e-tip-bottom {
	height: 20px;
	width: 12px;
}

.customtip.e-tooltip-wrap .e-arrow-tip.e-tip-top {
	height: 20px;
	width: 12px;
}

.customtip.e-tooltip-wrap .e-arrow-tip.e-tip-left {
	height: 12px;
	width: 20px;
}

.customtip.e-tooltip-wrap .e-arrow-tip.e-tip-right {
	height: 12px;
	width: 20px;
}

.customtip.e-tooltip-wrap .e-arrow-tip-outer.e-tip-bottom {
	border-left: 6px solid transparent;
	border-right: 6px solid transparent;
	border-top: 20px solid #FFFFFF;
}

.app-sensor-container {
	margin-bottom: 10px;
	padding-bottom: 10px;
}

.e-chart {
	overflow: inherit !important;
}

.e-tooltip {
	z-index: 2 !important;
}

.admin-sensor-data {
	z-index: 1;
}
</style>