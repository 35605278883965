var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ejs-dialog',{ref:_vm.modalName,attrs:{"id":_vm.modalName,"target":_vm.sfTarget,"header":_vm.lblTitle,"isModal":"true","showCloseIcon":"true","width":'500px',"visible":"false","allowDragging":"true","cssClass":"scrollable"}},[_c('div',{staticClass:"dm-flex-column dm-overflow-y-auto dm-padding-md"},[_c('div',{staticClass:"dm-flex-row",staticStyle:{"height":"300px"}},[_c('dm-table',{ref:"grd",attrs:{"table":{
                    grdTableItems: _vm.grdItems,
                    grdTableRowOnClick: _vm.grdRowOnClick,
                    grdTableActionOnClick: _vm.grdActionOnClick,
                    grdTableSortBy: _vm.grdSortBy,
                    grdTableFields: _vm.grdFields,
                    class: _vm.grdClass
                }}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }