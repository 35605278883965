<template>
  <div class="drop-down-filter">
    <div class="group-drop-down-filter">
      <div class="group-drop-down-label">Group</div>
      <v-select id="group-filter" class="rounded" :clearable="false" :components="{ OpenIndicator }"
        :options="groupsStore.groupOptions" :value="groupsStore.selectedGroupOption" :filter="handleFuzzySearch"
        @input="handleChangeGroup" />
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapStores } from "pinia";

import { useGroupsStore } from "../js/store";

export default {
  name: 'group-filter',
  data: () => {
    return {
      // replace the dropdown svg icon with an empty span element
      OpenIndicator: {
        render: (createElement) => createElement('span'),
      },
    }
  },
  methods: {
    handleFuzzySearch(options, search) {
      const results = this.groupsStore.searchIndex.search(search);
      return results.map((result) => result.item);
    },
    handleChangeGroup(option) {
      const groupId = option.id;
      if (window.innerWidth < 700) {
        this.$router.push({ query: { groupId, gridView: true } });
      } else {
        this.$router.push({
          query: {
            ...this.$route.query,
            groupId,
            sensorId: undefined,
          }
        });
      }
      this.groupsStore.selectGroup(groupId);

      // Note: make sure the input field isn't focused after selecting a group (only required if using v-select)
      setTimeout(() => {
        if (document.activeElement) {
          document.activeElement?.blur();
        }
      });
    }
  },
  computed: {
    ...mapStores(useGroupsStore),
  }
}
</script>


<style>
.drop-down-filter {
  flex: 1 1 200px;
  align-self: center;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.drop-down-filter .e-control-wrapper {
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
  border-bottom: none !important;
}

.drop-down-filter .e-input-group {
  margin-bottom: 0;
}

.drop-down-filter .e-float-text {
  padding-left: 10px !important;
}

.drop-down-filter .e-control {
  padding-left: 10px !important;
}

.drop-down-filter .e-search-icon {
  margin-right: 10px !important;
}

.group-drop-down-filter {
  background: #fff;
  --vs-border-width: 0;
  --vs-dropdown-option--active-bg: #eee;
  --vs-dropdown-option--active-color: #319cf4;
  --vs-dropdown-option-padding: 8px 14px;
  --vs-dropdown-min-width: 275px;
  border-radius: 4px;
}

.group-drop-down-label {
  color: rgba(0, 0, 0, 0.54);
  font-size: 13px;
  line-height: 1.4;
  margin-bottom: -4px;
  padding: 0 0 0 10px;
  pointer-events: none;
  position: relative;
  transition: 0.2s cubic-bezier(0.4, 0, 0.4, 1);
  z-index: 2;
}

/* change label color on input focus */
.group-drop-down-label:has(~ .vs--open) {
  color: var(--vs-dropdown-option--active-color);
}

/* imitate the growing blue bar on the bottom on input focus */
.vs__dropdown-toggle::before {
  background: var(--vs-dropdown-option--active-color);
  bottom: 0;
  content: "";
  height: 2px;
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
  width: 0;
  transition: 0.2s cubic-bezier(0.4, 0, 0.4, 1);
}

.v-select.vs--open .vs__dropdown-toggle::before {
  width: 100%;
}

/* reduce dropdown options font size */
.vs__dropdown-menu {
  font-size: 14px;
  --vs-dropdown-z-index: 2000;
}

.vs__selected {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 80%;
}

/* swap down caret with a triangle to match current design */
.vs__open-indicator::before {
  color: rgba(0, 0, 0, 0.54);
  content: "\e969";
  cursor: pointer;
  font-family: "e-icons";
  font-size: 12px;
  display: block;
  text-align: center;
  width: 18px;
}

/* enforce selected values to be displayed in a single row for mobile */
.vs__selected-options {
  flex-wrap: nowrap !important;
  max-width: calc(100% - 27px);
  white-space: nowrap;
}

@media screen and (max-width: 500px) {
  .drop-down-filter {
    margin-left: 0;
    width: calc(100% - 238px);
  }
}
</style>