<template>
  <div class="dm-flex-row dm-overflow-y-auto dm-padding-left-sm dm-padding-right-md dm-padding-bottom-md dm-flex-wrap">
    <div
         class="dm-flex-row dm-margin-bottom-sm dm-justify-content-space-between app-mobile-hide dm-flex-wrap dm-width-100">
      <div class="dm-flex-row dm-align-items-center">
        <div class="custom-date">
          <ejs-datetimepicker cssClass="dateDesktop"
                              :value="start"
                              :minDate="moment().utc().subtract(2, 'weeks')"
                              :maxDate="end"
                              @change="handleStartChange"
                              placeholder="Start"
                              floatLabelType="Auto"
                              :width="'210px'" />
        </div>

        <div class="dm-padding-left-sm custom-date">
          <ejs-datetimepicker cssClass="dateDesktop"
                              :value="end"
                              @change="handleEndChange"
                              :minDate="start"
                              :maxDate="moment().utc()"
                              placeholder="End"
                              floatLabelType="Auto"
                              :width="'210px'" />
        </div>

        <div class="dm-padding-left-sm">
          <ejs-numerictextbox cssClass="interval-control"
                              floatLabelType="Auto"
                              :value="displayedInterval"
                              @change="handleIntervalChange"
                              format="n0"
                              :min="steps === 5 ? 5 : 0"
                              :step="steps"
                              :width="'60px'"
                              placeholder="Interval"
                              :showSpinButton="false"
                              :strictMode="true" />
          <span class="interval-units">{{ units }}</span>
        </div>

        <div class="dm-margin-left-sm">
          <ejs-button @click.native="handleSearch"
                      cssClass="btn-search-history">
            <span class="fa fa-search"></span>
          </ejs-button>
        </div>
      </div>
      <div class="dm-flex-row dm-margin-left-sm">
        <ejs-button @click.native="handleExportHistory"
                    cssClass="export-button"
                    :disabled="historyBtnDisabled">
          <i class="fa fa-download"></i> <span>Export Data</span>
        </ejs-button>
        <ejs-button @click.native="handleExportRequests"
                    cssClass="export-button dm-margin-left-sm"
                    :disabled="requestsBtnDisabled">
          <i class="fa fa-download"></i> <span>Export Requests</span>
        </ejs-button>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapActions, mapState, mapStores } from 'pinia';
import moment from 'moment-timezone';

import { useHistoryStore } from '../js/store';

export default {
  name: 'sensor-history-bar',
  props: ['deviceId'],
  methods: {
    moment,
    handleStartChange(e) {
      this.updateStart(e.value);
    },
    handleEndChange(e) {
      this.updateEnd(e.value);
    },
    handleIntervalChange(e) {
      // If no interactions, return
      if (e.isInteracted === false) {
        return;
      };
      if (this.units === 'mins') {
        if (e.value <= 0) {
          this.updateInterval(5);
        } else {
          this.updateInterval(e.value);
        }
      } else if (this.units === 'hrs') {
        if (e.value === 0) {
          this.updateInterval(55);
        } else {
          this.updateInterval(e.value * 60);
        }
      } else {
        if (e.value === 0) {
          this.updateInterval(23 * 60);
        } else {
          this.updateInterval(e.value * 1440);
        }
      }
    },
    async handleSearch() {
      this.getHistory(
        this.deviceId,
      );

      // load photos table
      this.historyStore.resetPhotos();
      await this.historyStore.getPhotosMetadata(this.deviceId);
      await this.historyStore.getPhotosPage(this.deviceId);
    },
    handleExportHistory() {
      this.exportHistory(
        this.deviceId,
      );
    },
    handleExportRequests() {
      this.exportRequests(this.deviceId);
    },
    ...mapActions(useHistoryStore, [
      'getHistory',
      'exportHistory',
      'exportRequests',
      'updateStart',
      'updateEnd',
      'updateInterval',
    ]),
  },
  computed: {
    ...mapStores(useHistoryStore),
    ...mapState(useHistoryStore, [
      'start',
      'end',
      'interval',
      'minInterval',
      'transmissions',
      'requests',
    ]),
    historyBtnDisabled() {
      return this.transmissions[this.deviceId] === undefined || this.transmissions[this.deviceId].length === 0;
    },
    requestsBtnDisabled() {
      return this.requests[this.deviceId] === undefined || this.requests[this.deviceId].length === 0;
    },
    displayedInterval() {
      if (this.interval < 60) {
        return this.interval;
      } else if (this.interval < 1440) {
        return Math.ceil(this.interval / 60);
      }
      // Days
      return Math.ceil(this.interval / 1440);
    },
    steps() {
      if (this.units === 'mins') {
        return 5;
      } else if (this.units === 'hrs') {
        return 1;
      }
      // Days
      return 1;
    },
    units() {
      if (this.interval < 60) {
        return 'mins';
      } else if (this.interval < 1440) {
        return 'hrs';
      }
      return 'days';
    },
  },
}

</script>

<style>
.export-button:disabled {
  background-color: #5b5b5b !important;
  color: #ffffff !important;
}

.interval-control {
  margin-bottom: 4px;
  margin-top: 16px;
  padding-top: 0;
}

.interval-units {
  height: 30px;
  display: inline-block;
  vertical-align: bottom;
}
</style>