<template src="./groupsEditModal.html"></template>

<script>
import { mapStores } from 'pinia';
import { event } from 'vue-gtag';

import dmModalMixin from '../../../dmFramework/dmJS/dmModalMixin'
import { useBaseStore } from '../../js/store';

export default {
    name: 'groups-edit-modal',
    mixins: [dmModalMixin],
    props: [
        "modalData",
    ],
    data: function () {
        return {
            modalName: 'groupsEditModal' + DM.getRandomGuid(),
            lblTitle: 'Add Group',
            name: '',
            address: '',
            city: '',
            state: '',
            zip: '',
            country: '',
            tempUnits: 'Fahrenheit',
            tempUnitsOptions: [
                {
                    text: 'Celsius',
                    value: 'Celsius',
                },
                {
                    text: 'Fahrenheit',
                    value: 'Fahrenheit',
                }
            ],
            isMetric: 'Imperial System',
            measureUnitOptions: [
                {
                    text: 'Imperial System',
                    value: 'Imperial System',
                },
                {
                    text: 'Metric System',
                    value: 'Metric System',
                }
            ],
            timezone: 'Eastern Standard Time',
            timezoneOptions: [],
            isActive: true,
            apiKey: ''
        }
    },
    computed: {
        ...mapStores(useBaseStore),
    },
    methods: {
        saveGroup() {
            let isEdit = false;
            let data = DM.copy(this.modalData.group);

            if (this.modalData.mode === 'edit') {
                event('edit_group_click', {
                    'edit_group_id': this.modalData.group.ID
                });

                data.GroupID = this.modalData.group.ID;
                isEdit = true;
            }
            else {
                event('add_group_click');
            }

            Object.assign(data, {
                Name: this.name,
                Address: this.address,
                City: this.city,
                State: this.state,
                ZipCode: this.zip,
                Country: this.country,
                TimeZone: this.timezone || 'Eastern Standard Time',
                TemperatureUnits: this.tempUnits,
                IsFrostTechInternal: false,
                IsActive: this.isActive,
                IsMetric: this.isMetric === 'Metric System' ? 1 : 0,
            });

            return DM.http({
                method: !isEdit ? "POST" : "PUT",
                url: !isEdit ? "/groups" : `/groups/${this.modalData.group.ID}`,
                data: data
            });
        },
        btnSaveOnClick() {
            this.saveGroup()
                .then(() => {
                    this.modalData.callback();
                    this.modalRef.hide();
                });
        },
        btnCancelOnClick() {
            this.modalRef.hide();
        },
        getTimezones() {
            DM.http({
                method: 'GET',
                url: '/groups/time-zones'
            }).then(response => {
                this.timezoneOptions = response.map(t => {
                    return {
                        text: t.Display,
                        value: t.Value
                    }
                });
            });
        },
        addSearchIcon(ref) {
            if (ref) {
                DM.addSearchIconDropDown(ref);
            }
        }
    },
    created() {
        this.getTimezones();

        let group = this.modalData.group;
        this.mode = this.modalData.mode;

        if (this.modalData.mode === 'edit') {
            this.lblTitle = 'Edit Group: ' + this.modalData.group.Name;
            this.name = group.Name;
            this.address = group.Address;
            this.city = group.City;
            this.state = group.State;
            this.zip = group.ZipCode;
            this.country = group.Country;
            this.timezone = group.TimeZone;
            this.tempUnits = group.TemperatureUnits;
            this.isActive = group.IsActive;
            this.apiKey = group.APIKey;
            this.isMetric = group.IsMetric ? "Metric System" : "Imperial System";
        }
    },
}
</script>
