import { defineStore } from "pinia";
import { forEach, cloneDeep, reduce, map, round } from "lodash";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import moment from "moment-timezone";
import { config, event } from "vue-gtag";

import app from "./app";

import api from "./api";
import {
  sanityCheckComputerVisionData,
  transformForecastData,
  transformRequestData,
  transformTransmissionOrRoadCondition,
} from "./transformers";
import {
  exportToCSV,
  computerVisionValueGreaterThanFiftyPercent,
} from "./utils";
import { sortBy } from "lodash";
import Fuse from "fuse.js";

const API = new api();

export const useBaseStore = defineStore("base", {
  state: () => ({
    user: {
      Preferences: {
        chartLimit: 72,
        glanceExpanded: true,
        glanceSelectedKey: "frostVision",
      },
    },
    userID: "",
    authToken: "",
    modals: [],
    selectedLocationID: null,
    notifications: {
      items: [],
      isLoading: false,
      groupID: null,
    },
  }),
  getters: {
    preferences(state) {
      return state.user.Preferences;
    },
    defaultGroupId(state) {
      return (
        state.user.Preferences?.SensorMapViewDisplay?.[0]?.Group?.ID || null
      );
    },
  },
  actions: {
    async getUserByID(userId) {
      const response = await API.getUserById(userId);
      this.user = {
        ...response,
        Preferences: {
          ...response.Preferences,
          ...this.user.Preferences,
        },
      };
      const configOptions = {
        userId: response.ID,
      };
      if (response.JobTitle) {
        configOptions.job_title = response.JobTitle;
      }
      config(configOptions);
      DM.localStorageSet(
        app.localStoragePreferencesName,
        JSON.stringify(this.user.Preferences)
      );
    },
    setAuthentication() {
      this.authToken = DM.localStorageGet(app.localStorageAuthTokenName);
      this.userID = DM.localStorageGet(app.localStorageUserIDName);
      config({ user_id: this.userID });
      this.user = {
        ...this.user,
        Preferences: JSON.parse(
          DM.localStorageGet(app.localStoragePreferencesName) || "{}"
        ),
      };
    },
    signOutUser() {
      DM.localStorageRemove(app.localStorageAuthTokenName);
      DM.localStorageRemove(app.localStorageUserIDName);
      DM.localStorageRemove(app.localStoragePreferencesName);
      this.modals = [];
      this.user = null;
      this.userID = "";
      this.authToken = "";

      this.router.push({ name: "login" });

      event({
        event_category: "User",
        event_label: "Sign Out",
      });
    },
    removeModal(eventData) {
      this.modals = this.modals.filter(
        (m) => m.modalRefGuid !== eventData.modalRefGuid
      );
    },
    setPreferences(preferences) {
      this.user.Preferences = {
        ...this.user.Preferences,
        ...preferences,
      };
      DM.localStorageSet(
        app.localStoragePreferencesName,
        JSON.stringify(this.user.Preferences)
      );
    },
  },
});

// Groups store
export const useGroupsStore = defineStore("groups", {
  state: () => ({
    groups: [],
    selectedGroupId: null,
    groupOptions: [],
    fetchingGroups: false,
    activeAlerts: [],
    searchIndex: null,
  }),
  getters: {
    selectedGroup() {
      const id = this.selectedGroupId;
      if (!id) {
        return {};
      }
      return this.groups.find((g) => g.ID === id) || {};
    },
    selectedGroupOption() {
      const id = this.selectedGroupId;
      if (!id) {
        return {};
      }
      return {
        id: this.selectedGroup.ID,
        label: this.selectedGroup.Name,
      };
    },
  },
  actions: {
    async getGroups() {
      // Only fetch groups if we aren't in the process of fetching them
      // if (this.fetchingGroups) {
      //   return
      // }
      this.fetchingGroups = true;
      const response = await DM.http({
        method: "GET",
        url: `/groups`,
      });
      this.fetchingGroups = false;
      this.groups = response;
      this.groupOptions = response.map((g) => {
        return {
          label: g.Name,
          id: g.ID,
        };
      });
      this.groupOptions = sortBy(this.groupOptions, "label");
      this.searchIndex = new Fuse(this.groupOptions, {
        keys: ["label"],
      });
    },
    async selectGroupFromPreferences(groupIdFromRoute) {
      const baseStore = useBaseStore();
      if (this.groups.length === 0) {
        await this.getGroups();
      }
      if (groupIdFromRoute) {
        this.selectGroup(groupIdFromRoute);
      } else if (baseStore.defaultGroupId) {
        // Does the default group exist within the groups
        // We have run into an issue where default groups exist that the user does
        // not have access to.
        const defaultGroup = this.groups.find(
          (g) => g.ID === baseStore.defaultGroupId
        );
        if (defaultGroup) {
          this.selectGroup(baseStore.defaultGroupId);
        } else {
          this.selectFirstGroup();
        }
      } else if (this.selectedGroupId === null) {
        this.selectFirstGroup();
      }
    },
    async selectGroup(groupId) {
      if (this.groups.length === 0) {
        await this.getGroups();
      }
      if (groupId === this.selectedGroupId) {
        return;
      }
      this.selectedGroupId = groupId;
      const locationsStore = useLocationsStore();
      locationsStore.getGroupLocations(false, true);

      const historyStore = useHistoryStore();
      historyStore.updateStartWithTimezone(this.selectedGroup.TimeZone);

      this.getActiveAlerts();
    },
    selectFirstGroup() {
      const id = this.groupOptions[0].id;
      this.selectGroup(id);
    },
    getActiveAlerts() {
      const groupsStore = useGroupsStore();
      if (!groupsStore.selectedGroupId) {
        return;
      }

      this.clearActiveAlerts();
      API.getAlerts(this.selectedGroupId).then((response) => {
        this.activeAlerts = response.map((alert) => {
          return {
            MapPinColor: alert.MapPinColor,
            AlertID: alert.AlertID,
            DeviceID: alert.DeviceID,
            GroupID: alert.GroupID,
          };
        });
      });
    },
    clearActiveAlerts() {
      this.activeAlerts = [];
    },
  },
});

// Locations store
export const useLocationsStore = defineStore("locations", {
  state: () => ({
    searchText: "",
    retries: 0,
    delay: app.RETRY_DELAY,
    locations: {}, // {[groupID]: [zones for group]}
    locationsLastUpdated: null,
    fetchingGroupLocations: false,
    groupLocationsError: null,
    dataTransmissions: {}, // {[deviceID]: [transmissions for device]}
    mostRecentTransmissions: {}, // {[deviceID]: {}}
    transmissionsRetries: 0,
    transmissionsDelay: app.RETRY_DELAY,
    fetchingTransmissions: false,
    transmissionsError: null,
    images: {},
    imagesRetries: 0,
    imagesDelay: app.RETRY_DELAY,
    fetchingImages: false,
    imagesError: null,
    forecasts: {}, // {[deviceID]: [forecasts for device]}
    forecastsRetries: 0,
    forecastsDelay: app.RETRY_DELAY,
    fetchingForecasts: false,
    forecastsError: null,
    startedWorkDeviceIds: [],
    imageTransmissions: {},
    imageTransmissionsRetries: 0,
    imageTransmissionsDelay: app.RETRY_DELAY,
    fetchingImageTransmissions: false,
    imageTransmissionsError: null,
    glanceItems: {},
  }),
  getters: {
    sensorId() {
      return this.router.currentRoute.query.sensorId;
    },
    filteredLocations() {
      // Returns the locations for the selected group
      const groupsStore = useGroupsStore();
      if (!groupsStore.selectedGroupId) {
        return [{}];
      }
      const selected = this.locations[groupsStore.selectedGroupId];
      if (!selected) {
        return [];
      }
      return [selected];
    },
    devices() {
      // Flattens the locations into an array of locations
      const groupId = useGroupsStore().selectedGroupId;
      const devices = [];
      const filteredLocations = this.locations[groupId];
      if (!filteredLocations) {
        return [];
      }
      filteredLocations.Zones.forEach((zone) => {
        zone.Locations.forEach((location) => {
          devices.push(location);
        });
      });
      return devices;
    },
    devicesByIds() {
      return this.devices.reduce((acc, curr) => {
        acc[curr.ID] = curr;
        return acc;
      }, {});
    },
  },
  actions: {
    getGroupLocations(isRefresh = false, hideAlert = true) {
      this.getLocations(isRefresh, hideAlert).then((locations) => {
        this.getLatestImages();
        // Get IDs from the selected group's locations
        const start = moment().subtract(4, "hours").toISOString();
        const end = moment().add(72, "hours").toISOString();
        this.getDataTransmissions([]);
        this.getForecasts();
      });
    },
    getImageTransmissions(deviceId) {
      const groupsStore = useGroupsStore();
      if (!groupsStore.selectedGroupId) {
        return;
      }
      let ids = [deviceId];
      if (ids.length === 0) {
        return;
      }
      this.fetchingImageTransmissions = true;
      API.getTransmissions({ ids, includesPhotosOnly: true })
        .then((response) => {
          this.fetchingImageTransmissions = false;
          const imageTransmissions = cloneDeep(this.imageTransmissions);
          forEach(response, ({ DataTransmissions }, deviceID) => {
            imageTransmissions[deviceID] = DataTransmissions.map((t) => {
              return transformTransmissionOrRoadCondition(
                t,
                {
                  timeZone: groupsStore.selectedGroup.TimeZone,
                  tempUnit: groupsStore.selectedGroup.TemperatureUnits,
                },
                true
              );
            }).sort((a, b) =>
              moment(b.ImageCaptureDateTimeUTC).diff(a.ImageCaptureDateTimeUTC)
            );
          });
          this.imageTransmissions = imageTransmissions;
          this.imageTransmissionsDelay = app.RETRY_DELAY;
          this.imageTransmissionsRetries = 0;
        })
        .catch((error) => {
          this.imageTransmissionsRetries++;
          this.imageTransmissionsDelay = this.imageTransmissionsDelay * 2;
          if (this.imageTransmissionsRetries < app.MAX_RETRY_COUNT) {
            setTimeout(() => {
              // Show the error on the last request
              this.getImageTransmissions(deviceId);
            }, this.imageTransmissionsDelay);
          } else {
            this.fetchingImageTransmissions = false;
            this.imageTransmissionsError = error;
            this.imageTransmissionsRetries = 0;
            this.imageTransmissionsDelay = app.RETRY_DELAY;
          }
        });
    },
    getLatestImages() {
      const groupsStore = useGroupsStore();
      if (!groupsStore.selectedGroupId) {
        return;
      }
      this.fetchingImages = true;
      API.getLatestImages(groupsStore.selectedGroupId)
        .then((response) => {
          this.fetchingImages = false;
          this.images = response.reduce(
            (acc, curr) => {
              acc[curr.DeviceID] = curr.ProcessedCameraImageURLs;
              return acc;
            },
            {
              ...this.images,
            }
          );
          this.imagesDelay = app.RETRY_DELAY;
          this.imagesRetries = 0;
        })
        .catch((error) => {
          this.imagesRetries++;
          this.imagesDelay = this.imagesDelay * 2;
          if (this.retries < app.MAX_RETRY_COUNT) {
            setTimeout(() => {
              // Show the error on the last request
              this.getLatestImages();
            }, this.imagesDelay);
          } else {
            this.fetchingImages = false;
            this.imagesError = error;
          }
        });
    },
    async getLocations(isRefresh = false, hideAlert = true) {
      // Fetches locations for the map view for a selected group
      // Also triggers a getGroupNotification event
      const groupsStore = useGroupsStore();
      if (!groupsStore.selectedGroupId) {
        console.log("No group selected");
        return;
      }
      this.fetchingGroupLocations = true;
      try {
        const response = await API.getLocations(
          this.searchText,
          groupsStore.selectedGroupId,
          isRefresh,
          hideAlert
        );

        const locs = response.map((group) => {
          group.Zones.forEach((zone) => {
            zone.GroupID = group.GroupID;
            zone.TimeZone = group.TimeZone;
            zone.ZoneName = zone.ZoneName ? zone.ZoneName : "No Zone";
            zone.Locations.forEach((location) => {
              location.TimeZone = group.TimeZone;
            });
          });
          return group;
        });
        this.locations = {
          ...this.locations,
          [groupsStore.selectedGroupId]: locs[0],
        };
        this.fetchingGroupLocations = false;
        this.retries = 0;
        this.delay = app.RETRY_DELAY;
        // eventBus.$emit('getGroupNotification', groupsStore.selectedGroupId)
      } catch (error) {
        this.retries++;
        this.delay = this.delay * 2;
        if (this.retries < app.MAX_RETRY_COUNT) {
          setTimeout(() => {
            // Show the error on the last request
            this.getLocations(
              this.locations.length > 0,
              this.retries !== app.MAX_RETRY_COUNT - 1
            );
          }, this.delay);
        } else {
          this.fetchingGroupLocations = false;
          this.groupLocationsError = error;
          this.retries = 0;
          this.delay = app.RETRY_DELAY;
        }
      }
    },
    // Passing in deviceIds will only fetch transmissions for those devices
    // otherwise it grabs all devices for the selected group
    // The group is currently needed because we have to grab timezone and temp units
    getDataTransmissions(deviceIds = []) {
      const groupsStore = useGroupsStore();
      if (!groupsStore.selectedGroupId) {
        return;
      }
      let ids = deviceIds;
      if (ids.length === 0) {
        ids = this.devices.map((d) => d.ID.toString());
      }
      // If there are no devices, don't make the request
      if (ids.length === 0) {
        return;
      }
      this.fetchingTransmissions = true;
      API.getTransmissions({ ids })
        .then((response) => {
          this.fetchingTransmissions = false;
          const { TemperatureUnits: tempUnit, TimeZone: timeZone } =
            groupsStore.selectedGroup;
          const dataTransmissions = cloneDeep(this.dataTransmissions);
          const mostRecentTransmissions = cloneDeep(
            this.mostRecentTransmissions
          );
          forEach(
            response,
            ({ DataTransmissions, MostRecentDataTransmission }, deviceID) => {
              dataTransmissions[deviceID] = DataTransmissions.map((t) => {
                return transformTransmissionOrRoadCondition(t, {
                  timeZone,
                  tempUnit,
                });
              }).sort((a, b) =>
                moment(b.TransmissionDateTimeUTC).diff(
                  a.TransmissionDateTimeUTC
                )
              );

              mostRecentTransmissions[deviceID] =
                transformTransmissionOrRoadCondition(
                  MostRecentDataTransmission,
                  {
                    timeZone,
                    tempUnit,
                  }
                );
            }
          );
          this.dataTransmissions = dataTransmissions;
          this.mostRecentTransmissions = mostRecentTransmissions;
        })
        .catch((error) => {
          this.transmissionsRetries++;
          this.transmissionsDelay = this.transmissionsDelay * 2;
          if (this.transmissionsRetries < app.MAX_RETRY_COUNT) {
            setTimeout(() => {
              // Show the error on the last request
              this.getDataTransmissions(deviceIds);
            }, this.transmissionsDelay);
          } else {
            this.fetchingTransmissions = false;
            this.transmissionsError = error;
            this.transmissionsRetries = 0;
            this.transmissionsDelay = app.RETRY_DELAY;
          }
        });
    },
    // Passing in deviceIds will only fetch forecasts for those devices
    // otherwise it grabs all devices for the selected group
    // The group ID is required by the API for now
    getForecasts(deviceIds = []) {
      const groupsStore = useGroupsStore();
      if (!groupsStore.selectedGroupId) {
        return;
      }

      let ids = deviceIds;
      if (ids.length === 0) {
        ids = this.devices.map((d) => d.ID);
      }
      if (ids.length === 0) {
        return;
      }
      this.fetchingForecasts = true;
      const forecasts = cloneDeep(this.forecasts);
      API.getForecasts(ids, groupsStore.selectedGroupId)
        .then((response) => {
          this.fetchingForecasts = false;
          const clonedResponse = cloneDeep(response);
          forEach(clonedResponse, (forecast, deviceID) => {
            forecasts[deviceID] = {
              CurrentCondition: forecast.CurrentCondition,
              CurrentWeather: forecast.CurrentWeather,
              ForecastData: forecast.ForecastData.map((f) =>
                transformForecastData(f, {
                  timeZone: groupsStore.selectedGroup.TimeZone,
                  tempUnit: groupsStore.selectedGroup.TemperatureUnits,
                })
              ),
              ForecastPrecipSummary: forecast.ForecastPrecipSummary,
              ForecastWeather: forecast.ForecastWeather,
              ID: forecast.ID,
              RoadCondition: forecast.RoadCondition,
              RoadConditionsHistory: forecast.RoadConditionsHistory.map(
                (rc) => {
                  return transformTransmissionOrRoadCondition(rc, {
                    timeZone: groupsStore.selectedGroup.TimeZone,
                    tempUnit: groupsStore.selectedGroup.TemperatureUnits,
                  });
                }
              ),
              WeatherCondition: forecast.WeatherCondition,
            };
          });
          this.forecasts = forecasts;
        })
        .catch((error) => {
          this.fetchingForecasts = false;
          this.forecastsError = error;
          this.forecastsRetries++;
          this.forecastsDelay = this.forecastsDelay * 2;
          if (this.forecastsRetries < app.MAX_RETRY_COUNT) {
            setTimeout(() => {
              // Show the error on the last request
              this.getForecasts();
            }, this.forecastsDelay);
          } else {
            this.fetchingForecasts = false;
            this.forecastsError = error;
            this.forecastsRetries = 0;
            this.forecastsDelay = app.RETRY_DELAY;
          }
        });
    },
    pullDevicesFromLocations() {
      // Flattens the locations into an array of locations
      const devices = [];
      this.locations.forEach((group) => {
        group.Zones.forEach((zone) => {
          zone.Locations.forEach((location) => {
            devices.push(location);
          });
        });
      });
      return devices;
    },
    getLocation(deviceId) {
      const groupStore = useGroupsStore();
      if (!deviceId || !groupStore.selectedGroupId) {
        return;
      }
      API.getLocation(deviceId).then((response) => {
        const groupsStore = useGroupsStore();
        const { TemperatureUnits: tempUnit, TimeZone: timeZone } =
          groupsStore.selectedGroup;
        const location = response;
        location.TimeZone = timeZone;
        const groupId = location.GroupID;
        let found = false;
        this.locations = {
          ...this.locations,
          [groupId]: {
            ...this.locations[groupId],
            Zones: this.locations[groupId].Zones.map((zone) => {
              return {
                ...zone,
                Locations: zone.Locations.map((l) => {
                  if (l.ID === location.ID) {
                    found = true;
                    return location;
                  }
                  return l;
                }),
              };
            }),
          },
        };
        if (!found) {
          console.log("Location not found");
        }
        const dataTransmissions = cloneDeep(this.dataTransmissions);
        const locationTransmissions = location.DataTransmissions.filter(
          // Remove forecasts
          (d) => !d.IsForecast
        )
          .map((t) => {
            return transformTransmissionOrRoadCondition(t, {
              timeZone,
              tempUnit,
            });
          })
          .sort((a, b) =>
            moment(b.TransmissionDateTimeUTC).diff(a.TransmissionDateTimeUTC)
          );
        dataTransmissions[location.ID] = locationTransmissions;
        this.dataTransmissions = dataTransmissions;
      });
    },
    async getGlanceData(groupID, type) {
      const groupsStore = useGroupsStore();
      const baseStore = useBaseStore();
      const priorityType = type || baseStore.preferences.glanceSelectedKey;
      const id = groupID || groupsStore.selectedGroupId;
      if (!priorityType || !id) {
        return;
      }
      try {
        const response = await API.getPriorityItems(id, priorityType);
        this.glanceItems = reduce(
          response,
          (acc, curr, id) => {
            const cvData = sanityCheckComputerVisionData(curr);
            if (!computerVisionValueGreaterThanFiftyPercent(cvData)) {
              return acc;
            }
            acc[id] = {
              ...curr,
              ComputerVision: cvData,
            };
            return acc;
          },
          {}
        );
      } catch (error) {
        console.error(error);
      }
    },
    async startWork(deviceId) {
      API.startWork(deviceId).then((response) => {
        if (response && response.Message === "SUCCESS") {
          DM.alertShow(
            `The work event was successfully started and has captured a photo/reading prior to beginning work. 
                              You may now begin your work and once finished, please click "End" Work to complete the work event and a final photo/reading will be captured for proof of work.`,
            "Begin Work Event Success"
          );
          this.startedWorkDeviceIds = [...this.startedWorkDeviceIds, deviceId];
        } else {
          DM.alertShow(
            "Start Work Event request wasn't successful. Please try again",
            "Begin Work Event Failed"
          );
        }
      });
    },
    async endWork(deviceId) {
      API.endWork(deviceId).then((response) => {
        if (response && response.Message === "SUCCESS") {
          DM.alertShow(
            `The work event was successfully completed and will capture a photo/reading for proof of work which can take several minutes to download. Once downloaded, the proof of work can be viewed in the Requests tab under History.`,
            "End Work Event Success"
          );
          this.startedWorkDeviceIds = this.startedWorkDeviceIds.filter(
            (id) => id !== deviceId
          );
        } else {
          DM.alertShow(
            "End Work Event request wasn't successful. Please try again",
            "End Work Event Failed"
          );
        }
      });
    },
    requestPhoto(deviceId) {
      return new Promise((resolve, reject) => {
        API.requestPhoto(deviceId).then((response) => {
          if (response && response.allowPhoto === 0) {
            DM.alertShow(
              `Photos can only be requested after waiting ${response.waitMinutes} minutes from the last request`,
              "Request Photo Failed"
            );
            reject();
          }
          if (response && response.allowPhoto === 1) {
            DM.alertShow(
              "The photo was successfully requested and will take a couple minutes to download.",
              "Request Photo Success"
            );
            return resolve();
          }
        });
      });
    },
    startEvent(deviceId) {
      API.startEvent(deviceId).then((response) => {
        if (response && response.connected) {
          this.startedEventDeviceIds.push(deviceId);
          DM.alertShow(
            "The weather event was successfully started and will continue to take readings every 20 minutes for the next 4 hours",
            "Weather Event Success"
          );
        } else {
          DM.alertShow(
            "Weather event request wasn't successful. Please try again",
            "Weather Event Failed"
          );
        }
      });
    },
    async triggerDefrost(deviceId) {
      API.triggerDefroster(deviceId).then((response) => {
        if (response && response.connected) {
          DM.alertShow(
            "The defrost cycle was successfully started and will take up to 5 minutes to complete.",
            "Defrost Success"
          );
        } else {
          DM.alertShow(
            "Defrost request wasn't successful. Please try again",
            "Defrost Failed"
          );
        }
      });
    },
  },
});

// History store
export const useHistoryStore = defineStore("history", {
  state: () => ({
    transmissions: {},
    roadConditionsHistory: {},
    historyRetries: 0,
    historyDelay: app.RETRY_DELAY,
    fetchingHistory: false,
    historyError: null,
    requests: {},
    requestsRetries: 0,
    requestsDelay: app.RETRY_DELAY,
    fetchingRequests: false,
    requestsError: null,
    start: moment().subtract(1, "day").toISOString(),
    end: moment().toISOString(),
    interval: 5,
    minInterval: 5,

    photos: { metadata: [], pages: {} },

    fetchingPhotos: false,
    photosError: null,
    photosRetries: 0,
    photosDelay: app.RETRY_DELAY,

    fetchingPhotosMetadata: false,
    photosMetadataError: null,
    photosMetadataRetries: 0,
    photosMetadataDelay: app.RETRY_DELAY,
  }),
  actions: {
    updateStartWithTimezone(timezone) {
      const userTimezone = moment.tz.guess();
      this.start = moment(this.start)
        .tz(timezone)
        .tz(userTimezone, true)
        .toISOString();
      this.end = moment(this.end)
        .tz(timezone)
        .tz(userTimezone, true)
        .toISOString();
    },
    updateStart(dateTime) {
      // Start time can't be set to a time after the end time
      if (moment(dateTime).isAfter(this.end)) {
        this.start = this.end;
      } else {
        this.start = moment(dateTime).toISOString();
      }
      this.updateInterval();
    },
    updateEnd(dateTime) {
      // End time can't be set to a time before the start time
      // or more than now
      if (moment(dateTime).isBefore(this.start)) {
        this.end = this.start;
      } else if (moment(dateTime).isAfter(moment())) {
        this.end = moment().toISOString();
      } else {
        this.end = moment(dateTime).toISOString();
      }
      this.updateInterval();
    },
    // We are updating the interval based on the difference between start and end
    // We are also rounding the interval to the nearest 5 minutes
    // As the time difference increases, the interval increases
    // 2 days or less: 5 minutes
    // 3 days or less: 10 minutes
    // 7 days or less: 15 minutes
    // 14 days or less: 30 minutes
    // 30 days or less: 60 minutes
    // 90 days or less: 120 minutes
    // 90 days or more: 240 minutes
    updateInterval(rawInterval) {
      const interval = rawInterval || this.interval;
      const start = moment(this.start);
      const end = moment(this.end);
      const diff = end.diff(start, "days");
      let multOfFiveInterval = Math.ceil(interval / 5) * 5;
      switch (true) {
        case diff <= 2:
          this.interval = Math.max(multOfFiveInterval, 5);
          break;
        case diff <= 3:
          this.interval = Math.max(multOfFiveInterval, 10);
          this.minInterval = 10;
          break;
        case diff <= 7:
          this.interval = Math.max(multOfFiveInterval, 15);
          this.minInterval = 15;
          break;
        case diff <= 14:
          this.interval = Math.max(multOfFiveInterval, 30);
          this.minInterval = 30;
          break;
        case diff <= 30:
          this.interval = Math.max(multOfFiveInterval, 60);
          this.minInterval = 60;
          break;
        case diff <= 90:
          this.interval = Math.max(multOfFiveInterval, 120);
          this.minInterval = 120;
          break;
        default:
          this.interval = Math.max(multOfFiveInterval, 240);
          this.minInterval = 240;
          break;
      }
    },
    getHistory(deviceId) {
      const groupStore = useGroupsStore();
      if (!deviceId || !groupStore.selectedGroupId) {
        return;
      }
      const { selectedGroup } = groupStore;
      const timeZone = selectedGroup.TimeZone;
      this.fetchingHistory = true;
      API.getLocation(deviceId, {
        start: moment(this.start).tz(timeZone, true).utc().toISOString(),
        end: moment(this.end).tz(timeZone, true).utc().toISOString(),
        minutesInterval: this.interval,
      })
        .then((response) => {
          this.fetchingHistory = false;
          // Removing forecasts from transmissions
          const history = response.DataTransmissions.filter(
            (t) => !t.IsForecast
          )
            .map((t) => {
              return transformTransmissionOrRoadCondition(t, {
                timeZone: selectedGroup.TimeZone,
                tempUnit: selectedGroup.TemperatureUnits,
              });
            })
            // Sorting them by reverse chronological order
            .sort((a, b) =>
              moment(b.TransmissionDateTimeUTC).diff(a.TransmissionDateTimeUTC)
            );

          const transmissions = cloneDeep(this.transmissions);
          const roadConditionsHistory = cloneDeep(this.roadConditionsHistory);
          this.transmissions = {
            ...transmissions,
            [deviceId]: history,
          };
          this.roadConditionsHistory = {
            ...roadConditionsHistory,
            [deviceId]: response.RoadConditionsHistory,
          };
          this.historyDelay = app.RETRY_DELAY;
          this.historyRetries = 0;
        })
        .catch((error) => {
          this.historyRetries++;
          this.historyDelay = this.historyDelay * 2;
          if (this.historyRetries < app.MAX_RETRY_COUNT) {
            setTimeout(() => {
              // Show the error on the last request
              this.getHistory(deviceId);
            }, this.historyDelay);
          } else {
            this.fetchingHistory = false;
            this.historyError = error;
            this.historyRetries = 0;
            this.historyDelay = app.RETRY_DELAY;
          }
        });
    },
    getPhotosPage(deviceId, offset = 0, limit = 10) {
      const groupStore = useGroupsStore();
      if (!deviceId || !groupStore.selectedGroupId) {
        return;
      }
      const { selectedGroup } = groupStore;
      const timeZone = selectedGroup.TimeZone || "America/New_York";
      const tempUnit = selectedGroup.TemperatureUnits || "F";
      this.fetchingPhotos = true;

      const start = moment(this.start).tz(timeZone, true).utc().toISOString();
      const end = moment(this.end).tz(timeZone, true).utc().toISOString();

      return API.getPhotos(deviceId, {
        start,
        end,
        limit,
        offset,
      })
        .then((response) => {
          response = response.map((r) => {
            const t = transformTransmissionOrRoadCondition(r, {
              timeZone,
              tempUnit,
            });
            t.ComputerVision = map(
              t.ComputerVision,
              (v, k) => `<div>${k}: ${round(v, 1)}%</div>`
            ).join("");
            return t;
          });

          const newData = { ...this.photos };
          newData.pages[offset] = response;
          this.photos = { ...newData };

          this.fetchingPhotos = false;
        })
        .catch((error) => {
          this.photosRetries++;
          this.photosDelay = this.photosDelay * 2;
          if (this.photosRetries < app.MAX_RETRY_COUNT) {
            setTimeout(() => {
              // Show the error on the last request
              this.getPhotosPage(deviceId, offset, limit);
            }, this.photosDelay);
          } else {
            this.fetchingPhotos = false;
            this.photosError = error;
            this.photosRetries = 0;
            this.photosDelay = app.RETRY_DELAY;
          }
        });
    },
    getPhotosMetadata(deviceId) {
      const groupStore = useGroupsStore();
      if (!deviceId || !groupStore.selectedGroupId) {
        return;
      }
      const { selectedGroup } = groupStore;
      const timeZone = selectedGroup.TimeZone;
      this.fetchingPhotosMetadata = true;

      const start = moment(this.start).tz(timeZone, true).utc().toISOString();
      const end = moment(this.end).tz(timeZone, true).utc().toISOString();

      return API.getPhotosMetadata(deviceId, {
        start,
        end,
      })
        .then((response) => {
          this.fetchingPhotosMetadata = false;
          this.photos = {
            ...this.photos,
            metadata: response,
          };
        })
        .catch((error) => {
          this.photosMetadataRetries++;
          this.photosMetadataDelay = this.photosMetadataDelay * 2;
          if (this.photosMetadataRetries < app.MAX_RETRY_COUNT) {
            setTimeout(() => {
              // Show the error on the last request
              this.getPhotosMetadata(deviceId);
            }, this.photosMetadataDelay);
          } else {
            this.fetchingPhotosMetadata = false;
            this.photosMetadataError = error;
            this.photosMetadataRetries = 0;
            this.photosMetadataDelay = app.RETRY_DELAY;
          }
        });
    },
    resetPhotos() {
      this.photos = { metadata: [], pages: {} };
    },
    getRequests(deviceId) {
      const groupStore = useGroupsStore();
      if (!deviceId || !groupStore.selectedGroupId) {
        return;
      }
      const timeZone = groupStore.selectedGroup.TimeZone;
      this.fetchingRequests = true;
      API.getRequests(deviceId)
        .then((response) => {
          this.fetchingRequests = false;
          this.requests = {
            ...this.requests,
            [deviceId]: response.map((r) =>
              transformRequestData(r, { timeZone })
            ),
          };
          this.requestsDelay = app.RETRY_DELAY;
          this.requestsRetries = 0;
        })
        .catch((error) => {
          this.requestsRetries++;
          this.requestsDelay = this.requestsDelay * 2;
          if (this.requestsRetries < app.MAX_RETRY_COUNT) {
            setTimeout(() => {
              // Show the error on the last request
              this.getRequests(deviceId);
            }, this.requestsDelay);
          } else {
            this.fetchingRequests = false;
            this.requestsError = error;
            this.requestsRetries = 0;
            this.requestsDelay = app.RETRY_DELAY;
          }
        });
    },
    exportRequests(deviceId) {
      const requestData = this.requests[deviceId];
      if (!requestData) {
        return;
      }

      const doc = new jsPDF();
      autoTable(doc, {
        head: [
          [
            "Start Date Time (Local)",
            "CreatedByUser",
            "DeviceID",
            "Type",
            "Request ID",
          ],
        ],
        body: requestData.map((r) => [
          r.startDateTimeFormatted,
          r.CreatedByUser,
          r.DeviceID,
          r.DisplayName,
          r.ID,
        ]),
      });
      const now = new Date();
      const date = now.toJSON().slice(0, 10);
      doc.save(`${date}-request-data-sensor-${deviceId}.pdf`);
    },
    exportHistory(deviceId) {
      const groupsStore = useGroupsStore();
      const timeZone = groupsStore.selectedGroup.TimeZone;
      let history = this.transmissions[deviceId];
      const roadConditions = this.roadConditionsHistory[deviceId];

      roadConditions.forEach((rc) => {
        const index = history.findIndex(
          (e) =>
            // Match the date if its within 5 minutes
            moment(e.TransmissionDateTimeUTC).diff(
              rc.TransmissionDateTimeUTC,
              "minutes"
            ) < 5
        );

        const condition = {
          SurfaceGrip: rc.SurfaceGrip,
          RoadCondition: rc.RoadCondition,
          PrecipType: rc.PrecipType,
          PrecipRate: rc.PrecipRate,
          SnowRate: rc.SnowRate,
          RainRate: rc.RainRate,
          MixedRate: rc.MixedRate,
          WindDirection: rc.WindDirection,
          WindSpeed: rc.WindSpeed,
          IsForecast: rc.IsForecast,
        };

        if (index > -1) {
          history[index] = {
            ...history[index],
            ...condition,
          };
        }
      });
      history = history.sort((a, b) =>
        moment(a.TransmissionDateTimeUTC).diff(b.TransmissionDateTimeUTC)
      );

      const start = moment(this.start).format("YYYY-MM-DD_HH:mm");
      const end = moment(this.end).format("YYYY-MM-DD_HH:mm");

      const arr = history.reduce((a, e) => {
        if (e.IsForecast) return a;

        a.push({
          TransmissionDateTimeUTC: e.TransmissionDateTimeUTC,
          TransmissionDateTimeLocal: e.chartDate,
          SurfaceTemp: e.SurfaceTemp,
          AirTemp: e.AirTemp,
          DewPoint: e.DewPoint,
          Humidity: e.Humidity,
          ProcessedCameraImageURL: e.ProcessedCameraImageURL,
          SurfaceGrip: e.SurfaceGrip,
          RoadCondition: e.RoadCondition,
          PrecipType: e.PrecipType,
          PrecipRate: e.PrecipRate,
          SnowRate: e.SnowRate,
          RainRate: e.RainRate,
          MixedRate: e.MixedRate,
          WindDirection: e.WindDirection,
          WindSpeed: e.WindSpeed,
          IsForecast: e.IsForecast,
        });

        return a;
      }, []);

      exportToCSV(arr, `sensor_ID_${deviceId}_history_(${start}_${end})`);
    },
  },
});
