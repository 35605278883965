<template>
  <form class="search-form"
        @submit="searchOnClick">
    <div class="e-input-group">
      <ejs-textbox floatLabelType="Auto"
                   showClearButton="true"
                   class="e-small"
                   placeholder="Search"
                   :value=locationsStore.searchText
                   @change="onClear"></ejs-textbox>
      <ejs-button type="submit"
                  class="e-input-btn">
        <span class="fa fa-search"></span>
      </ejs-button>
    </div>

  </form>
</template>

<script setup>
import Vue from 'vue';

import { useLocationsStore } from '../js/store';

const locationsStore = useLocationsStore();

// I can't find a way to catch the clear event so I am just checking if the value is empty
const onClear = (val) => {
  if (locationsStore.searchText !== '' && val.value === '') {
    locationsStore.searchText = '';
    locationsStore.getLocations();
  }
};

const searchOnClick = (event) => {
  event.preventDefault();
  const searchText = event.target[0].value;
  locationsStore.searchText = searchText;
  locationsStore.getLocations();
};

</script>
<style>
.search-form {
  align-self: center;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  flex: 1 1 200px;
}

.search-form .e-input-group {
  margin-bottom: 0;
  border-radius: 20px;
}

.search-form .e-control-wrapper {
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
  border-bottom: none !important;
  border-radius: 20px !important;
}

.search-form .e-float-text {
  padding-left: 10px !important;
}

.search-form .e-control {
  padding-left: 10px !important;
  border-radius: 4px 20px 20px 4px
}

.search-form button {
  z-index: 1;
}

.search-form .e-clear-icon {
  margin-right: 10px !important;
}
</style>