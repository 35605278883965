<template src="./alertsPage.html"></template>

<script>
import Vue from 'vue'
import { mapStores } from 'pinia';
import { event } from 'vue-gtag';

import { useBaseStore } from '../../js/store';

export default {
    name: 'alerts-page',
    data: function () {
        return {
            grdAlertsFields: [],
            grdAlertsItems: [],
            grdAlertsSortBy: {
                columns: [
                    {
                        field: 'GroupEmbedded.Name',
                        direction: 'Ascending'
                    },
                ]
            },
            signedInUserIsAdmin: false,
            group: null,
            groupOptions: [],
            searchText: '',
            grdAlertsActions: [],
            selectedRow: null,

            // TODO: Removed if unused
            // countyOptions: [],
            // searchOrganization: '',
            // organizationOptions: [],
            // searchCounty: '',
            // searchAlertType: '',
            // signedInUserIsSuperAdmin: false,
        }
    },
    computed: {
        ...mapStores(useBaseStore),
        isUserAdmin() {
            return this.baseStore.user?.IsSuperAdmin || this.baseStore.user.Groups?.filter(g => g.Permission === 'Admin').length > 0;
        }
    },
    methods: {
        getAlerts() {
            DM.http({
                method: 'GET',
                url: '/alerts',
                params: {
                    groupID: this.group,
                    search: this.searchText ? this.searchText : undefined
                }
            }).then(response => {
                // TODO: Removed comment if unused;
                // this.alerts = response;
                this.grdAlertsItems = response;
            });
        },
        grdAlertsRowOnClick(args) {
            this.selectedRow = args;
        },
        addAlertOnClick() {
            eventBus.$emit('showAppModal', this, 'alertsEditModal', {
                mode: "new",
                alert: {},
                callback: this.getAlerts
            });
        },
        grdAlertsActionOnClick(args) {
            if (args.item.text === 'Edit') {
                this.openEditModal(this.selectedRow.rowData.ID);
            }

            if (args.item.text === 'View People') {
                event('view_alert_people_click', {
                    label: 'alert_id',
                    value: this.selectedRow.rowData.ID,
                });

                this.openPeopleModal(this.selectedRow.rowData.ID);
            }

            if (args.item.text === 'View Locations') {
                event('view_alert_locations_click', {
                    label: 'alert_id',
                    value: this.selectedRow.rowData.ID
                });

                this.openLocationsModal(this.selectedRow.rowData.ID);
            }

            if (args.item.text === 'Toggle Disabled') {
                this.disableAlert(this.selectedRow.rowData.ID);
            }

            if (args.item.text === 'Delete') {
                DM.confirmShow('Are you sure you want to delete this alert?', '', () => {
                    this.deleteAlert(this.selectedRow.rowData.ID);
                }, null, null, 'No');
            }
        },
        openEditModal(alertID) {
            DM.http({
                method: 'GET',
                url: `/alerts/${alertID}`,
            }).then(response => {
                eventBus.$emit('showAppModal', this, 'alertsEditModal', {
                    mode: 'edit',
                    alert: response,
                    callback: this.getAlerts
                });
            });
        },
        openPeopleModal(alertID) {
            DM.http({
                method: 'GET',
                url: `/alerts/${alertID}`,
            }).then(response => {
                const checkSquareIcon = '<i class="fa fa-check-square dm-pointer app-grid-checkbox"></i>';
                const squareIcon = '<i class="far fa-square dm-pointer app-grid-checkbox"></i>'
                eventBus.$emit('showAppModal', this, 'gridListViewModal', {
                    mode: 'view',
                    title: 'View People',
                    items: response.Recipients.map((user) => ({ ...user, Name: `${user.UserEmbedded.LastName}, ${user.UserEmbedded.FirstName}` })),
                    fields: [
                        {
                            key: 'Name',
                            label: 'Name',
                        },
                        {
                            key: 'PhoneNumber',
                            label: 'Phone',
                            formatter: (column, data) => {
                                column.rowData = data;
                                return data.Phone ? checkSquareIcon : squareIcon;
                            },
                            width: '70px'
                        },
                        {
                            key: 'Email',
                            label: 'Email',
                            formatter: (column, data) => {
                                column.rowData = data;
                                return data.Email ? checkSquareIcon : squareIcon
                            },
                            width: '70px'
                        }
                    ]
                });
            });
        },
        openLocationsModal(alertID) {
            DM.http({
                method: 'GET',
                url: `/alerts/${alertID}`,
            }).then(response => {
                eventBus.$emit('showAppModal', this, 'gridListViewModal', {
                    mode: 'view',
                    title: 'View Locations',
                    items: response.Locations,
                    fields: [
                        {
                            key: 'Name',
                            label: 'Name',
                        }
                    ]
                });
            });
        },
        disableAlert(alertID) {
            event('toggle_alert_disable_click', {
                label: 'alert_id',
                value: this.selectedRow.rowData.ID
            });

            DM.http({
                method: 'PUT',
                url: `/alerts/${alertID}/disabled`,
                data: {
                    Value: !this.selectedRow.rowData.IsDisabled
                }
            }).then(response => {
                this.getAlerts();
            });
        },
        deleteAlert(alertID) {
            event('delete_alert_click', {
                label: 'alert_id',
                value: this.selectedRow.rowData.ID
            });

            DM.http({
                method: 'DELETE',
                url: `/alerts/${alertID}`,
            }).then(() => {
                this.getAlerts();
            });
        },
        searchAlertsOnClick() {
            this.getAlerts();
        },
        getGroupOptions() {
            DM.http({
                method: 'GET',
                url: '/groups',
            }).then(response => {
                this.groupOptions = response.map(g => {
                    return {
                        text: g.Name,
                        value: g.ID
                    }
                });
            });
        },
        addSearchIcon(ref) {
            if (ref) {
                DM.addSearchIconDropDown(ref);
            }
        },

        defineColumns() {
            let self = this;

            if (window.innerWidth < 666) {
                this.grdAlertsFields = [
                    {
                        type: 'template',
                        width: '51px',
                        template: () => {
                            return {
                                template: Vue.component('columnTemplate', {
                                    template: `<ejs-dropdownbutton
                                            :items="grdAlertsActions"
                                            :select="select"
                                            iconCss="e-icons e-ellipsis-vert"
                                            cssClass= "e-caret-hide"
                                        />`,
                                    data: function () {
                                        return {
                                            grdAlertsActions: self.grdAlertsActions
                                        }
                                    },
                                    methods: {
                                        select(args) {
                                            self.grdAlertsActionOnClick(args);
                                        }
                                    }
                                })
                            }
                        }
                    },
                    {
                        key: 'Name',
                        label: 'Name',
                        formatter(column, data) {
                            return data.Name ? data.Name : data.ConditionsSummary;
                        },
                        allowSorting: true,
                    },
                    {
                        key: 'IsDisabled',
                        label: 'Disabled',
                        formatter(column, data) {
                            return data.IsDisabled ? 'Y' : 'N';
                        },
                        width: '90px',
                        classes: { class: ['dm-text-center'] }
                    },
                ];
            } else {
                this.grdAlertsFields = [
                    {
                        type: 'template',
                        width: '51px',
                        template: () => {
                            return {
                                template: Vue.component('columnTemplate', {
                                    template: `<ejs-dropdownbutton
                                            :items="grdAlertsActions"
                                            :select="select"
                                            iconCss="e-icons e-ellipsis-vert"
                                            cssClass= "e-caret-hide"
                                        />`,
                                    data: function () {
                                        return {
                                            grdAlertsActions: self.grdAlertsActions
                                        }
                                    },
                                    methods: {
                                        select(args) {
                                            self.grdAlertsActionOnClick(args);
                                        }
                                    }
                                })
                            }
                        }
                    },
                    {
                        key: 'Name',
                        label: 'Name',
                        formatter(column, data) {
                            return data.Name ? data.Name : '--';
                        },
                        allowSorting: true,
                        width: '100px',
                    },
                    {
                        key: 'ConditionsSummary',
                        label: 'Conditions',
                        allowSorting: true
                    },
                    {
                        key: 'GroupEmbedded.Name',
                        label: 'Group',
                        allowSorting: true
                    },
                    {
                        key: 'LocationsSummary',
                        label: 'Locations',
                        allowSorting: true
                    },
                    {
                        key: 'PeopleSummary',
                        label: 'People'
                    },
                    {
                        key: 'AlertRange',
                        label: 'Alert Range'
                    },
                    {
                        key: 'IsDisabled',
                        label: 'Disabled',
                        formatter(column, data) {
                            return data.IsDisabled ? 'Y' : 'N';
                        },
                        width: '90px',
                        classes: { class: ['dm-text-center'] }
                    },
                ];
            }
        },
    },
    created() {
        this.defineColumns();
        this.getGroupOptions();
        this.getAlerts();

        const isUserAdmin = this.baseStore.user.IsSuperAdmin || this.baseStore.user.Groups?.filter(g => g.Permission === 'Admin').length > 0;

        if (isUserAdmin) {
            this.grdAlertsActions = [
                {
                    text: 'Edit'
                },
                {
                    text: 'View Locations'
                },
                {
                    text: 'View People'
                },
                {
                    text: 'Toggle Disabled'
                },
                {
                    text: 'Delete'
                }
            ];
        } else {
            this.grdAlertsActions = [
                {
                    text: 'View Locations'
                },
                {
                    text: 'View People'
                }
            ];
        }
    },
    mounted() {
        let defineColumns;
        window.onresize = () => {
            clearTimeout(defineColumns);
            defineColumns = setTimeout(this.defineColumns, 100);
        };
    }
}
</script>
