<template src="./settingsPage.html"></template>

<script>
import Vue from "vue";
import { mapStores } from "pinia";
import { useBaseStore } from "../../js/store";

    export default {
        name: "settings-page",
        data: function() {
            return {
                searchText: "",
                includeInactive: false,
            };
        },
        methods: {
            handleNavigation(name) {
                if(this.$router.name !== name) {
                    this.$router.push({ name });
                }
            },
            handleOpenModalClick(event) {
                eventBus.$emit(event);
            },
            searchOnClick() {
                eventBus.$emit("searchItems", {
                    search: this.searchText,
                    includeInactive: this.includeInactive,
                });
            },
        },
        watch: {
            templateName: function(newVal) {
                this.searchText = "";
                this.includeInactive = false;
            },
        },
        computed: {
            ...mapStores(useBaseStore),
            isUserSuperAdmin() {
                return this.baseStore.user?.IsSuperAdmin;
            },
        },
        created() {},
        mounted() {},
    };
</script>
