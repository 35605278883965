<template>
  <v-select :clearable="false" class="glance-selector" :filterable="false" :searchable="false" :value="selected"
    :options="options" @input="onChange" placeholder="Select an option"></v-select>
</template>
<script>
export default {
  name: "at-a-glance-selector",
  props: {
    selected: {
      type: Object,
      default: null,
    },
    options: {
      type: Array,
      default: () => [],
    },
    onChange: {
      type: Function,
      default: () => { },
    },
  },
};
</script>
<style>
.glance-selector {
  margin-left: .5rem;
  min-width: 120px;
}

.glance-selector .vs__search::placeholder,
.glance-selector .vs__dropdown-toggle,
.glance-selector .vs__dropdown-menu {
  background: #19293E;
  border: solid 1px #5f6e89;
  border-radius: 8px;
  color: #fff !important;
}

.glance-selector .vs__selected {
  color: white;
  font-size: .8rem;
  margin: 0;
}

.glance-selector .vs__search,
.glance-selector .vs__search:focus {
  padding: 0;
  margin: 0;
}

.glance-selector .vs__dropdown-toggle {
  padding: 0;
  margin: 0;
}

.glance-selector .vs__clear,
.glance-selector .vs__open-indicator {
  fill: #394066;
  color: #fff;
}
</style>