<template>
  <div class="grid-wrapper" :style="gridWrapperStyle">
    <template v-if="mainAppWidth >= 500">
      <slot></slot>
    </template>
    <div class="device-grid" :style="deviceGridStyle">
      <template v-if="mainAppWidth < 500">
        <slot></slot>
      </template>
      <template v-for="group in locationsStore.filteredLocations">
        <template v-for="zone in group.Zones">
          <div v-for="location in zone.Locations" :key="location.ID" class="sensor-item">
            <grid-sensor-item :location="location" :most-recent-transmission="locationsStore.mostRecentTransmissions[location.ID]
    " :data-transmissions="locationsStore.dataTransmissions[location.ID] || []
    " :forecast="locationsStore.forecasts[location.ID]" :images="locationsStore.images[location.ID]"
              :show-grid-view="showGridView"></grid-sensor-item>
          </div>
        </template>
      </template>
    </div>
  </div>
</template>
<script setup>
import { computed } from "vue";
import { useLocationsStore } from "../js/store";

import gridSensorItem from "./gridSensorItem";

const locationsStore = useLocationsStore();

const props = defineProps({
  mainAppWidth: Number,
  gridHeight: Number,
  showGridView: Boolean,
  showSensorView: Boolean,
});
const gridItemWidth = computed(function () {
  const width =
    props.showGridView === true ? props.mainAppWidth / 2 : props.mainAppWidth;
  const threeTwenty = Math.floor(width / 340);
  const fourTwenty = Math.floor(width / 440);
  if (threeTwenty > fourTwenty && threeTwenty < 3) {
    return 300;
  } else {
    return 400;
  }
});

const gridWrapperStyle = computed(function () {
  if (props.showSensorView === true && props.mainAppWidth < 768) {
    return { display: "none" };
  }
  if (props.showGridView === true) {
    return { width: "100%" };
  }

  const width = props.mainAppWidth / 2 || props.mainAppWidth;
  if (gridItemWidth.value === 300 && width >= 340) {
    return { width: Math.floor(width / 340) * 340 + "px" || "340px" };
  } else if (gridItemWidth.value === 400 && width >= 440) {
    return { width: Math.floor(width / 440) * 440 + "px" || "440px" };
  } else {
    return { width: "100%" };
  }
});
const deviceGridStyle = computed(function () {
  return {
    gridTemplateColumns: `repeat(auto-fill, minmax(${gridItemWidth.value}px, 1fr))`,
    height: props.gridHeight - 20 + "px",
  };
});
</script>
