import Vue, { markRaw } from "vue";
import VueRouter from "vue-router";
import { createPinia, PiniaVuePlugin } from "pinia";

import App from "./App.vue";

import dmAlertModal from "../dmFramework/dmJS/dmAlertModal";
import dmConfirmModal from "../dmFramework/dmJS/dmConfirmModal";
import dmTable from "../dmFramework/dmJS/dmTable.vue";
import DM from "../dmFramework/dmJS/dmUtils";
import app from "./js/app.js";
import router from "./js/routes";
import axios from "axios";

import { ButtonPlugin, CheckBoxPlugin } from "@syncfusion/ej2-vue-buttons";
import {
  MenuPlugin,
  AccordionPlugin,
  TabPlugin,
} from "@syncfusion/ej2-vue-navigations";
import { GridPlugin } from "@syncfusion/ej2-vue-grids";
import { DialogPlugin, TooltipPlugin } from "@syncfusion/ej2-vue-popups";
import {
  TextBoxPlugin,
  SliderPlugin,
  NumericTextBoxPlugin,
} from "@syncfusion/ej2-vue-inputs";
import {
  DatePickerPlugin,
  DateTimePickerPlugin,
  TimePickerPlugin,
} from "@syncfusion/ej2-vue-calendars";
import { DropDownButtonPlugin } from "@syncfusion/ej2-vue-splitbuttons";
import {
  DropDownListPlugin,
  MultiSelectPlugin,
} from "@syncfusion/ej2-vue-dropdowns";
import { MultiSelect, CheckBoxSelection } from "@syncfusion/ej2-dropdowns";
import { ChartPlugin } from "@syncfusion/ej2-vue-charts";
import { UploaderPlugin } from "@syncfusion/ej2-vue-inputs";
import { ColorPickerPlugin } from "@syncfusion/ej2-vue-inputs";
import { enableRipple } from "@syncfusion/ej2-base";

import VueObserveVisibility from "vue-observe-visibility";
import VueSelect from "vue-select";
import VueGtag from "vue-gtag";

import initializeCloudWatch from "./js/cloudwatch.config.js";

import "vue-select/dist/vue-select.css";
import "./css/syncfusion-material.all.scss";
import "./css/app.scss";

Vue.component("dmAlertModal", dmAlertModal);
Vue.component("dmConfirmModal", dmConfirmModal);
Vue.component("dmTable", dmTable);
Vue.component("v-select", VueSelect);

window.DM = DM.utils;
window.app = app;
window.axios = axios;

Vue.use(VueGtag, {
  config: { id: process.env.VUE_APP_GOOGLE_ANALYTICS_ID },
});

window.awsRum = initializeCloudWatch();

Vue.config.errorHandler = function (err) {
  window.awsRum.recordError(err);
};

window.onerror = function (message, source, lineno, colno, error) {
  if (message === 'Error: <text> attribute y: Expected length, "NaN".') return;
};

Vue.config.warnHandler = function (msg, _vm, _trace) {
  if (
    // Ignore this error as it has to do with vue version and useVue
    msg !==
    "[Vue warn]: onMounted is called when there is no active component instance to be associated with. Lifecycle injection APIs can only be used during execution of setup()."
  ) {
    console.warn(msg);
  }
};

Vue.use(PiniaVuePlugin);
Vue.use(VueRouter);

Vue.use(ButtonPlugin);
Vue.use(CheckBoxPlugin);
Vue.use(MenuPlugin);
Vue.use(AccordionPlugin);
Vue.use(TabPlugin);
Vue.use(GridPlugin);
Vue.use(DialogPlugin);
Vue.use(NumericTextBoxPlugin);
Vue.use(TextBoxPlugin);
Vue.use(DatePickerPlugin);
Vue.use(DateTimePickerPlugin);
Vue.use(TimePickerPlugin);
Vue.use(DropDownButtonPlugin);
Vue.use(DropDownListPlugin);
Vue.use(MultiSelectPlugin);
Vue.use(ChartPlugin);
Vue.use(SliderPlugin);
Vue.use(UploaderPlugin);
Vue.use(TooltipPlugin);
enableRipple(true);
Vue.use(ColorPickerPlugin);
Vue.use(VueObserveVisibility);

MultiSelect.Inject(CheckBoxSelection);

window.eventBus = new Vue();

const pinia = createPinia();

pinia.use(({ store }) => {
  store.router = markRaw(router);
});
new Vue({
  el: "#app",
  pinia,
  router,
  render: (h) => h(App),
});

export default pinia;
