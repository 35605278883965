export default {
  'dry-surface': '#666971',
  'wet-surface': '#c2d689',
  'slush-surface': '#c1ecad',
  'snow-surface': '#f9b1b1',
  'mixed-surface': '#ffcf8c',
  snowing: '#d3ccf7',
  rain: '#9cd3e8',
  mixed: '#c3f2f5'
}
