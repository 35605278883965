import { reduce } from "lodash";
import {
  convertCelciusToFahrenheit,
  convertFahrenheitToCelcius,
  convertPrecipRate,
  convertWindSpeed,
  formatChartDate,
  formatDecimal,
  formatWindDirection,
} from "./utils";
// We use this to transform transmissions, road conditions
export const transformTransmissionOrRoadCondition = (
  transmission,
  { tempUnit, currentCondition, timeZone },
  isPhotosOnly = false
) => {
  const tz = timeZone || "America/Chicago";
  const r = { ...transmission };
  const isMetric = tempUnit !== "Fahrenheit";
  r.SurfaceGrip = Math.round((r?.SurfaceGrip ?? 0) * 100) / 100;

  if (r.hasOwnProperty("AirTemp") && r.AirTemp !== 0) {
    r.AirTemp = convertFahrenheitToCelcius(r.AirTemp, tempUnit);
  }
  if (r.hasOwnProperty("AirTempt")) {
    r.AirTemp = convertFahrenheitToCelcius(r.AirTempt, tempUnit);
  }
  r.SurfaceTemp = convertFahrenheitToCelcius(r?.SurfaceTemp, tempUnit);
  if (r.Humidity) {
    r.Humidity = formatDecimal(r?.Humidity);
  }
  if (r.DewPoint) {
    r.DewPoint = convertFahrenheitToCelcius(r?.DewPoint, tempUnit);
  }
  if (currentCondition) {
    r.WindSpeed = convertWindSpeed(currentCondition.WindSpeed, isMetric);
    r.WindDirection = formatWindDirection(currentCondition.WindDirection);
  } else {
    r.WindSpeed = convertWindSpeed(r.WindSpeed, isMetric);
  }

  if (r.hasOwnProperty("ComputerVision")) {
    r.ComputerVision = sanityCheckComputerVisionData(r);
  }

  r.SnowRate = convertPrecipRate(r.SnowRate, isMetric);
  r.RainRate = convertPrecipRate(r.RainRate, isMetric);
  r.MixedRate = convertPrecipRate(r.MixedRate, isMetric);
  if (r.hasOwnProperty("ImageCaptureDateTimeUTC")) {
    r.ImageCaptureLocal = formatChartDate(r.ImageCaptureDateTimeUTC, tz);
  }
  if (r.TransmissionDateTimeUTC) {
    r.chartDate = formatChartDate(r.TransmissionDateTimeUTC, tz);
  }
  return r;
};

export const transformForecastData = (forecastData, { tempUnit, timeZone }) => {
  const tz = timeZone || "America/Chicago";
  const r = { ...forecastData };
  const isMetric = tempUnit !== "Fahrenheit";

  r.SurfaceGrip = Math.round((r.SurfaceGrip ?? 0) * 100) / 100;

  if (r.hasOwnProperty("AirTempt")) {
    r.AirTemp = convertCelciusToFahrenheit(r.AirTempt, tempUnit);
  }
  r.SurfaceTemp = convertCelciusToFahrenheit(r.SurfaceTemp, tempUnit);

  if (r.hasOwnProperty("Humidity")) {
    r.Humidity = formatDecimal(r.Humidity);
  }
  if (r.hasOwnProperty("DewPoint")) {
    r.DewPoint = convertCelciusToFahrenheit(r.DewPoint, tempUnit);
  }
  if (r.hasOwnProperty("WindSpeed")) {
    r.WindSpeed = convertWindSpeed(r.WindSpeed, isMetric);
  }

  r.SnowRate = convertPrecipRate(r.SnowRate, isMetric);
  r.RainRate = convertPrecipRate(r.RainRate, isMetric);
  r.MixedRate = convertPrecipRate(r.MixedRate, isMetric);

  if (r.hasOwnProperty("TransmissionDateTimeUTC")) {
    r.TransmissionDateTimeUTC = r.TransmissionDateTimeUTC;
    r.chartDate = formatChartDate(r.TransmissionDateTimeUTC, tz);
  } else if (r.hasOwnProperty("ForecastDateTimeUTC")) {
    r.TransmissionDateTimeUTC = r.ForecastDateTimeUTC;
    r.chartDate = formatChartDate(r.ForecastDateTimeUTC, tz);
  }
  r.isForecast = true;
  return r;
};

export const transformRequestData = (requestData, { timeZone }) => {
  const tz = timeZone || "America/Chicago";
  const r = { ...requestData };
  if (r.hasOwnProperty("StartDateTimeUTC")) {
    r.startDateTimeFormatted = formatChartDate(r.StartDateTimeUTC, tz);
  }
  if (r.hasOwnProperty("EndDateTimeUTC")) {
    r.endDateTimeFormatted = formatChartDate(r.EndDateTimeUTC, tz);
  }
  return r;
};

const mappedKeys = {
  DayPartialSnowOnRoad: "Snow",
  DayClearPavement: "Dry",
  DayWetPavement: "Wet",
  DaySnowing: "Snowing",
  NightPartialSnowOnRoad: "Snow",
  NightClearPavement: "Dry",
  NightWetPavement: "Wet",
  DaySnowOnRoad: "Snow",
  NightSnowOnRoad: "Snow",
  NightSnowing: "Snowing",
};
const combineDayNightComputerVisionData = (cv) => {
  return reduce(
    cv,
    (acc, val, key) => {
      const mapped = mappedKeys[key];
      if (!mapped) {
        return acc;
      }
      acc[mapped] = acc[mapped] > 0 ? acc[mapped] + val : val;
      return acc;
    },
    {}
  );
};

export const sanityCheckComputerVisionData = (data) => {
  if (!data) {
    return null;
  }
  let cv;
  if (data.hasOwnProperty("ComputerVision")) {
    cv = combineDayNightComputerVisionData(data.ComputerVision);
  } else {
    cv = combineDayNightComputerVisionData(data);
  }
  // Using a negative here to make up for the lack of airtemp and surfacetemp on priority endpoint
  if (!data.AirTemp < 45 || !data.SurfaceTemp < 45) {
    delete cv.Snow;
    delete cv.Snowing;
  }
  if (data.SurfaceTemp <= 32) {
    delete cv.Wet;
  }
  return cv;
};
